import { useTrackDeletedContentCollectionLink } from '@air/analytics';
import { Form, ShortUrlResponse } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Box, TRANSACTION_MODAL_PRIMARY_CTA, TRANSACTION_MODAL_SECONDARY_CTA } from '@air/zephyr';
import { memo } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useDeleteShortId } from '~/swr-hooks/shortUrl/useDeleteShortId';
import { EditShareLinkView } from '~/types/ShareLink';

type ContentCollectionDeleteActionsProps = {
  form: Form;
  shortIdInfo: ShortUrlResponse;
  setView: (view: EditShareLinkView) => void;
};

export const ContentCollectionDeleteActions = memo(
  ({ form, shortIdInfo, setView }: ContentCollectionDeleteActionsProps) => {
    const { currentWorkspace } = useCurrentWorkspace();
    const { trackDeletedContentCollectionLink } = useTrackDeletedContentCollectionLink();
    const { deleteShortId } = useDeleteShortId();

    return (
      <Box
        tx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 32,
          '& > *:first-child': { mr: 12 },
        }}
      >
        <Button
          appearance="ghost"
          color="grey"
          data-testid={TRANSACTION_MODAL_SECONDARY_CTA}
          onClick={() => setView('edit')}
          size="large"
        >
          Cancel
        </Button>
        <Button
          appearance="filled"
          color="red"
          data-testid={TRANSACTION_MODAL_PRIMARY_CTA}
          onClick={() => {
            const workspaceId = currentWorkspace?.id;
            if (!workspaceId) {
              throw new Error('No workspace id');
            }
            deleteShortId.mutate(
              {
                workspaceId,
                shortId: shortIdInfo.id,
                boardContext: undefined,
                id: form.id,
              },
              {
                onSuccess: () => {
                  trackDeletedContentCollectionLink();
                  setView('create');
                },
              },
            );
          }}
          isLoading={deleteShortId.isLoading}
          size="large"
        >
          Delete link
        </Button>
      </Box>
    );
  },
);

ContentCollectionDeleteActions.displayName = 'ContentCollectionDeleteActions';
