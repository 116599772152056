import { Library } from '@air/api/types';
import { Skeleton } from '@air/component-skeleton';
import { Email, Team } from '@air/next-icons';

import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { LibraryDetailsAccessibility } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryDetails/components/LibraryDetailsAccessibility';
import { LibraryDetails } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryDetails/LibraryDetails';

export type LibraryDetailsContainerProps = {
  libraryId: Library['id'];
};

export const LibraryDetailsContainer = ({ libraryId }: LibraryDetailsContainerProps) => {
  const { data: library, isInitialLoading: isLibraryLoading } = useLibrary({ libraryId });

  return isLibraryLoading ? (
    <LibraryDetails
      accessibility={
        <>
          <div className="flex items-center gap-1">
            <Skeleton height={16} width={16} />
            <Skeleton height={18} isText width={120} />
          </div>
          <Skeleton height={18} isText />
        </>
      }
      description={
        <>
          <Skeleton height={24} isText width="100%" />
          <Skeleton height={24} isText width="80%" />
        </>
      }
      title={<Skeleton height={24} isText />}
    />
  ) : (
    <LibraryDetails
      accessibility={
        library?.accessibility ? (
          <LibraryDetailsAccessibility
            description={
              library.accessibility === 'private'
                ? 'An admin must approve a member’s request to join this library'
                : 'Any workspace member can join this library'
            }
            label={library.accessibility === 'private' ? 'Request to join' : 'Public to workspace'}
            icon={
              library.accessibility === 'private' ? (
                <Email className="h-4 w-4 fill-pigeon-500" />
              ) : (
                <Team className="h-4 w-4 fill-pigeon-500" />
              )
            }
          />
        ) : null
      }
      description={library?.description ? <p className="text-16 text-pigeon-600">{library.description}</p> : null}
      title={library?.title ? <div className="text-16 text-pigeon-600">{library?.title}</div> : null}
    />
  );
};
