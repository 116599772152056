import { Search } from '@air/api';
import { Board, Library } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const PRIVATE_SEARCH_SUGGESTIONS = 'PRIVATE_SEARCH_SUGGESTIONS';

export const getPrivateSuggestionsKey = (query: string, workspaceId?: string) => [
  PRIVATE_SEARCH_SUGGESTIONS,
  { query, workspaceId },
];

const limit = 8;

export const usePrivateSearchSuggestions = ({
  search,
  boardId,
  canFetch,
  libraryId,
}: {
  search: string;
  boardId?: Board['id'];
  libraryId?: Library['id'];
  canFetch: boolean;
}) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const query = qs.stringify({ search, boardId, libraryId });

  return useQuery(
    getPrivateSuggestionsKey(query, currentWorkspace?.id),
    () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Search.getSuggestions({ workspaceId, options: { search, limit, boardId, libraryId } });
    },
    { enabled: !!currentWorkspace?.id && !!canFetch },
  );
};
