import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader } from '@air/component-sidebar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useState } from 'react';

import { DesktopNotificationsPopover } from '~/components/AirNotifications/DesktopNotificationsPopover';
import { CurrentWorkspaceHeaderDropdown } from '~/components/CurrentWorkspaceHeader/CurrentWorkspaceHeaderDropdown';
import { FavoriteBoards } from '~/components/CurrentWorkspaceNav/BoardsNav/FavoriteBoards';
import { WorkspaceBoards } from '~/components/CurrentWorkspaceNav/BoardsNav/WorkspaceBoards';
import { CurrentWorkspaceNavCreateBoardButton } from '~/components/CurrentWorkspaceNav/CurrentWorkspaceNavCreateBoardButton';
import { TryLibrariesCTA } from '~/components/CurrentWorkspaceNav/TryLibrariesCTA/TryLibrariesCTA';
import { WorkspaceNavigation } from '~/components/CurrentWorkspaceNav/WorkspaceNavigation';
import { DragContainerIds } from '~/components/Shared/Drag/dragTypes';
import { SIDEBAR_SIZE } from '~/constants/localStorageKeys';
import { CURRENT_WORKSPACE_NAV_WIDTH } from '~/constants/sideNav';

import { LibraryNavigation } from '../LibraryBeta/LibraryNavigation/LibraryNavigation';

export const CurrentWorkspaceNav = memo(() => {
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>();

  const { libraries: librariesFlag } = useFlags();

  return (
    <Sidebar
      className="flex h-full w-[var(--sidebar-width)] flex-col items-stretch border-r border-r-grey-4 bg-grey-1"
      localStorageKey={SIDEBAR_SIZE}
      size={CURRENT_WORKSPACE_NAV_WIDTH}
    >
      <SidebarHeader>
        <CurrentWorkspaceHeaderDropdown />
        <DesktopNotificationsPopover />
      </SidebarHeader>

      <SidebarContent id={DragContainerIds['navBoard']} ref={(ref) => setScrollElement(ref)}>
        <WorkspaceNavigation />

        <div className="my-4 h-px bg-grey-4" />

        {!!scrollElement && (
          <>
            <FavoriteBoards scrollElement={scrollElement} />

            {librariesFlag ? (
              <LibraryNavigation scrollElement={scrollElement} />
            ) : (
              <WorkspaceBoards scrollElement={scrollElement} />
            )}
          </>
        )}
      </SidebarContent>

      <TryLibrariesCTA />
      <SidebarFooter>
        <CurrentWorkspaceNavCreateBoardButton />
      </SidebarFooter>
    </Sidebar>
  );
});

CurrentWorkspaceNav.displayName = 'CurrentWorkspaceNav';
