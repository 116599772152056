import { SavedFilters } from '@air/api';
import { SavedFiltersListResponse, WorkspaceInfo } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { canViewSavedFilters } from '~/utils/permissions/workspacePermissions';

const SAVED_FILTERS = 'SAVED_FILTERS';

export const getSavedFiltersKey = (workspaceId?: WorkspaceInfo['id']) => {
  return [SAVED_FILTERS, { workspaceId }];
};

export const useGetSavedFilters = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();

  return useQuery<SavedFiltersListResponse>(
    getSavedFiltersKey(currentWorkspace?.id),
    () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return SavedFilters.listSavedFilters({ workspaceId, options: { limit: 100, cursor: null, sortBy: 'createdAt' } });
    },
    { enabled: !!currentWorkspace?.id && canViewSavedFilters(permissions) },
  );
};
