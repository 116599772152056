import { useTrackJoinedLibrary } from '@air/analytics';
import { AddLibraryMemberInput, Libraries } from '@air/api';
import type { Library } from '@air/api/types';
import { useMutation } from '@tanstack/react-query';

import { isLibariesKey } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { queryClient } from '~/swr-hooks/queryClient';
import { getWorkspaceRolesKey } from '~/swr-hooks/workspaces/useWorkspaceRoles';

export type UseLibraryJoinMutationParams = {
  libraryId: Library['id'];
};

export const useLibraryJoinMutation = ({ libraryId }: UseLibraryJoinMutationParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackJoinedLibrary } = useTrackJoinedLibrary();

  const libraryJoinMutation = useMutation(
    (data: AddLibraryMemberInput[]) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.addMembers({ workspaceId, libraryId, members: data });
    },
    {
      onSuccess: () => {
        trackJoinedLibrary({
          library_id: libraryId,
        });

        /**
         * Library permissions aren't available until the user has a library in
         * their workspace. This is a temporary fix to refetch the workspace
         * roles after a library is present.
         * @see https://air-labs-team.slack.com/archives/C04G14SRBFG/p1681924232591519
         */
        queryClient.invalidateQueries(getWorkspaceRolesKey(currentWorkspace?.id));

        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => {
            return isLibariesKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
          },
        });
      },
    },
  );

  return { libraryJoinMutation };
};
