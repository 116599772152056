import { Spinner } from '@air/primitive-spinner';
import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps } from 'react';

import { NotificationsHeader, NotificationsHeaderProps } from './NotificationsHeader';
import { NotificationsList, NotificationsListProps } from './NotificationsList';

export type NotificationsProps<Notification = unknown, NotificationResponse = unknown> = ComponentProps<'div'> &
  NotificationsListProps<Notification, NotificationResponse> &
  Pick<NotificationsHeaderProps, 'filters' | 'onFilterClick' | 'onMarkAllAsRead' | 'onSettingsClick' | 'options'> & {
    isLoading?: boolean;
  };

export const Notifications = <Notification, NotificationResponse>({
  className,
  filters,
  getEstimateSize,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  notifications,
  onFetchNextPage,
  onFilterClick,
  onSettingsClick,
  options,
  onMarkAllAsRead,
  renderLoader,
  renderNotification,
  renderNullState,
  ...restOfProps
}: NotificationsProps<Notification, NotificationResponse>) => {
  return (
    <div className={tailwindMerge('flex flex-col', className)} data-testid="NOTIFICATIONS" {...restOfProps}>
      <NotificationsHeader
        filters={filters}
        onFilterClick={onFilterClick}
        onMarkAllAsRead={onMarkAllAsRead}
        onSettingsClick={onSettingsClick}
        options={options}
      />
      {isLoading ? (
        <div className="flex items-center justify-center py-10 text-teal-9">
          <Spinner />
        </div>
      ) : (
        <NotificationsList<Notification, NotificationResponse>
          getEstimateSize={getEstimateSize}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          notifications={notifications || []}
          onFetchNextPage={onFetchNextPage}
          renderLoader={renderLoader}
          renderNotification={renderNotification}
          renderNullState={renderNullState}
        />
      )}
    </div>
  );
};
