import { SavedFilters } from '@air/api';
import { SavedFiltersListResponse } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { createEmptyListResponseStructure } from '~/utils/ApiUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getSavedFilterKey } from './useGetSavedFilter';
import { getSavedFiltersKey } from './useGetSavedFilters';

export const useDeleteSavedFilter = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const deleteSavedFilter = useMutation({
    mutationFn: (savedFilterId: string) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return SavedFilters.deleteSavedFilter({ workspaceId, savedFilterId });
    },
    // When mutate is called:
    onMutate: async (savedFilterId) => {
      if (!currentWorkspace?.id) {
        return;
      }

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(getSavedFiltersKey(currentWorkspace.id));

      // Snapshot the previous value
      const previousSavedFilters = queryClient.getQueryData<SavedFiltersListResponse>(
        getSavedFiltersKey(currentWorkspace.id),
      );

      /** Remove the individual saved filter from cache */
      queryClient.setQueryData(getSavedFilterKey(savedFilterId), undefined);

      /** Remove the saved filter from list cache */
      queryClient.setQueryData<SavedFiltersListResponse>(getSavedFiltersKey(currentWorkspace.id), (cache) =>
        cache
          ? {
              ...cache,
              data: cache.data.filter((sv) => sv.id !== savedFilterId),
            }
          : createEmptyListResponseStructure(),
      );

      // Return a context object with the snapshotted value
      return { previousSavedFilters };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (error, savedFilterId, context) => {
      if (!currentWorkspace?.id) {
        return;
      }

      reportErrorToBugsnag({
        error,
        context: 'Unable to delete saved filter',
        metadata: {
          key: 'data',
          data: { savedFilterId, workspaceId: currentWorkspace?.id },
        },
      });

      queryClient.setQueryData(getSavedFiltersKey(currentWorkspace.id), context?.previousSavedFilters);
    },
  });

  return {
    deleteSavedFilter,
  };
};
