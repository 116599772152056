import { useTrackRemovedLibraryMember } from '@air/analytics';
import { Libraries } from '@air/api';
import type { Library, LibraryMemberWithInfo, RemoveLibraryMemberInput } from '@air/api/types';
import { useMutation } from '@tanstack/react-query';

import { getLibraryMembersKey } from '~/components/LibraryBeta/hooks/useLibraryMembers';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { queryClient } from '~/swr-hooks/queryClient';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type UseLibraryRemoveMembersMutationParams = {
  libraryId: Library['id'];
};

export const useLibraryMembersRemoveMutation = ({ libraryId }: UseLibraryRemoveMembersMutationParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackRemovedLibraryMember } = useTrackRemovedLibraryMember();

  const libraryMembersRemoveMutation = useMutation(
    (data: RemoveLibraryMemberInput[]) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.removeMembers({ workspaceId, libraryId, members: data });
    },
    {
      onMutate: (variables) => {
        const previousLibraryMembers = queryClient.getQueryData<LibraryMemberWithInfo[]>(
          getLibraryMembersKey(libraryId),
        );

        queryClient.cancelQueries(getLibraryMembersKey(libraryId));

        if (previousLibraryMembers) {
          queryClient.setQueryData<LibraryMemberWithInfo[]>(
            getLibraryMembersKey(libraryId),
            previousLibraryMembers.filter(
              (member) => !variables.some((variable) => variable.accountId === member.accountId),
            ),
          );
        }

        return {
          previousLibraryMembers,
        };
      },
      onError: (error, variables, context) => {
        if (context) {
          queryClient.setQueryData(getLibraryMembersKey(libraryId), context.previousLibraryMembers);
        }

        reportErrorToBugsnag({
          error,
          context: 'Unable to update library member',
          metadata: {
            key: 'data',
            data: {
              libraryId,
              member: { ...variables },
            },
          },
        });
      },
      onSuccess(_data, variables) {
        variables.forEach((member) => {
          trackRemovedLibraryMember({
            library_id: libraryId,
            library_member_id: member.accountId,
          });
        });

        queryClient.invalidateQueries(getLibraryMembersKey(libraryId));
      },
    },
  );

  return { libraryMembersRemoveMutation };
};
