import { NextSeo, NextSeoProps } from 'next-seo';
import { memo, useMemo } from 'react';

import { useGetNotificationSummary } from '~/components/AirNotifications/hooks/useGetNotificationSummary';

interface PageTitleProps extends NextSeoProps {}

export const PrivatePageTitle = memo(({ title = '', ...props }: PageTitleProps) => {
  const { data: notificationsSummary } = useGetNotificationSummary();

  const pageTitle = useMemo(() => {
    const unread = notificationsSummary?.unseen || 0;
    const countToDisplay = unread > 99 ? '+99' : unread;
    return countToDisplay > 0 ? `(${countToDisplay}) ${title}` : title;
  }, [notificationsSummary?.unseen, title]);

  return <NextSeo title={pageTitle} {...props} />;
});

PrivatePageTitle.displayName = 'PageTitle';
