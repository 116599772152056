import { useToasts } from '@air/provider-toast';

import { CustomFieldModal } from '~/components/CustomFields/CustomFieldModal/CustomFieldModal';
import { CustomFieldModalFormData } from '~/components/CustomFields/CustomFieldModal/CustomFieldModalForm/types';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useCreateWorkspaceCustomField } from '~/swr-hooks/customFields/useCreateWorkspaceCustomField';
import { useGetCustomFieldColors } from '~/swr-hooks/customFields/useGetCustomFieldColors';
import { useGetCustomFieldTypes } from '~/swr-hooks/customFields/useGetFieldTypes';

import { initialFormValue } from './initialCustomFieldFormData';

export interface CustomFieldCreateModalProps extends AirModalProps {
  onCreate: () => void;
}

export const CustomFieldsCreateModal = ({ onClose, onCreate }: CustomFieldCreateModalProps) => {
  const { data: colors } = useGetCustomFieldColors();
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: fieldTypesData } = useGetCustomFieldTypes();
  const { showToast } = useToasts();
  const { createWorkspaceCustomField } = useCreateWorkspaceCustomField();

  if (!currentWorkspace) {
    return null;
  }

  const handleSubmit = async (formValues: CustomFieldModalFormData) => {
    createWorkspaceCustomField.mutate(
      {
        formValues,
        colors: colors?.data || [],
      },
      {
        onError: () => {
          showToast('Failed to create custom field. Please try again.');
        },
        onSuccess: async () => {
          await onCreate();
          onClose();
        },
      },
    );
  };

  const fieldTypes = fieldTypesData?.data;
  const initialFieldType = fieldTypes?.[0]?.id;

  if (!initialFieldType) return null;

  return (
    <CustomFieldModal
      modalType="create"
      isLoading={createWorkspaceCustomField.isLoading}
      handleSubmit={handleSubmit}
      initialFormValues={{ ...initialFormValue, fieldTypeId: initialFieldType }}
      onClose={onClose}
      fieldTypes={fieldTypes}
      colors={colors?.data || []}
    />
  );
};
