import { ShortUrlObjectType } from '@air/api/types';
import { Modal, Text } from '@air/zephyr';
import { memo, useEffect, useState } from 'react';

import { ContentCollectionCopyLink } from '~/components/Modals/ContentCollectionModal/ContentCollectionCopyLink';
import { NoPermissionToCreateShareLink } from '~/components/NoPermissionToCreateShareLink';
import { SharePrivateContentSpinner } from '~/components/SharePrivateContentModal/SharePrivateContentSpinner';
import { CONTENT_COLLECTION_FORM_MODAL } from '~/constants/testIDs';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useFormPermissions } from '~/hooks/useFormPermissions';
import { useShortUrlPermissions } from '~/hooks/useShortUrlPermissions';
import { useListForms } from '~/swr-hooks/forms/useListForms';
import { useGetExistingShortIdInformation } from '~/swr-hooks/shortUrl/useGetExistingShortIdInformation';
import { EditShareLinkView } from '~/types/ShareLink';
import {
  canCreateBoardContentCollectionForm,
  canEditBoardContentCollectionForm,
  canSeeBoardContentCollectionForm,
} from '~/utils/permissions/boardPermissions';
import { canViewShortUrl } from '~/utils/permissions/shortUrlPermissions';

import { ContentCollectionDeleteActions } from './ContentCollectionDeleteActions';
import { ContentCollectionEditActions } from './ContentCollectionEditActions';
import { ContentCollectionGenerateActions } from './ContentCollectionGenerateActions';
import { CreateContentCollectionLink } from './CreateContentCollectionLink';

export type ContentCollectionModalProps = Pick<AirModalProps, 'onClose'> & {
  boardId: string;
  boardTitle: string;
};

export const ContentCollectionModal = memo(({ onClose, boardId, boardTitle }: ContentCollectionModalProps) => {
  const [view, setView] = useState<EditShareLinkView>('loading');

  const { boardPermissions } = useBoardPermissions({
    boardId,
  });

  const { data: forms, isInitialLoading: fetchingForms } = useListForms(boardId, {
    staleTime: Infinity,
    enabled: canSeeBoardContentCollectionForm(boardPermissions),
  });

  const form = forms?.data[0];

  useFetchObjectsPermissions({
    objects: {
      formIds: form?.id ? [form.id] : [],
    },
  });

  const { formPermissions } = useFormPermissions({
    formId: form?.id,
  });

  const { data: shortIdInfo, isFetched: fetchedShortIdInfo } = useGetExistingShortIdInformation({
    id: form?.id,
    type: ShortUrlObjectType.form,
    boardContext: undefined,
    canView: canViewShortUrl(formPermissions),
  });

  useFetchObjectsPermissions({
    objects: {
      shortIds: shortIdInfo?.id ? [shortIdInfo.id] : [],
    },
  });

  const { shortUrlPermissions } = useShortUrlPermissions({
    shortId: shortIdInfo?.id,
  });

  const isLoading = fetchingForms || !!(form && !fetchedShortIdInfo);

  useEffect(() => {
    if (isLoading) {
      setView('loading');
    } else {
      switch (view) {
        case 'loading':
          {
            if (shortIdInfo) {
              setView('edit');
            } else {
              setView('create');
            }
          }
          break;

        case 'create':
          {
            if (shortIdInfo) {
              setView('edit');
            }
          }
          break;

        default: {
          if (!shortIdInfo) {
            setView('create');
          }
        }
      }
    }
  }, [isLoading, setView, shortIdInfo, view]);

  return (
    <Modal
      modalLabel={
        view === 'delete'
          ? 'Delete this link?'
          : view === 'regenerate'
          ? 'Generate new link'
          : view === 'create' || view === 'edit'
          ? 'Share a content collection form'
          : undefined
      }
      modalDescription={
        view === 'regenerate' ? (
          `This will permanently expire the old link. Visitors will need to use the new link to access this form.`
        ) : view === 'delete' ? (
          'Visitors will no longer be able to access this form.'
        ) : view === 'create' || view === 'edit' ? (
          <Text tx={{ color: 'pigeon600', mb: 20, mt: 24 }} variant="text-ui-14">
            Create an upload form to collect assets and files from anyone.{' '}
          </Text>
        ) : undefined
      }
      onDismiss={onClose}
      dangerouslyBypassFocusLock
      isAlertModal={false}
      withCloseButton
      tx={{ width: 640 }}
      data-testid={`${CONTENT_COLLECTION_FORM_MODAL}-${view}`}
    >
      {canSeeBoardContentCollectionForm(boardPermissions) ? (
        view === 'loading' ? (
          <SharePrivateContentSpinner />
        ) : view === 'create' || !shortIdInfo || !form ? (
          canCreateBoardContentCollectionForm(boardPermissions) ? (
            <CreateContentCollectionLink boardId={boardId} boardTitle={boardTitle} form={form} />
          ) : (
            <NoPermissionToCreateShareLink />
          )
        ) : view === 'edit' ? (
          canEditBoardContentCollectionForm(shortUrlPermissions) ? (
            <ContentCollectionEditActions setView={setView} form={form} shortIdInfo={shortIdInfo} />
          ) : (
            <ContentCollectionCopyLink shortIdInfo={shortIdInfo} />
          )
        ) : view === 'delete' ? (
          <ContentCollectionDeleteActions form={form} shortIdInfo={shortIdInfo} setView={setView} />
        ) : view === 'regenerate' ? (
          <ContentCollectionGenerateActions form={form} shortIdInfo={shortIdInfo} setView={setView} />
        ) : (
          <ContentCollectionCopyLink shortIdInfo={shortIdInfo} />
        )
      ) : (
        /**
         * This scenario should never happen as the button to open this modal
         * shouldn't have been shown if the user doesn't have permission to view the share link
         */
        <NoPermissionToCreateShareLink />
      )}
    </Modal>
  );
});

ContentCollectionModal.displayName = 'ContentCollection';
