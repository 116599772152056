import { useTrackCompletedDragging } from '@air/analytics';
import { Box } from '@air/zephyr';
import Router from 'next/router';
import { memo, useCallback } from 'react';
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized';

import { DropRearrangeArea } from '~/components/DropRearrangeArea/DropRearrangeArea';
import { WORKSPACE_NAV_IMAGE_AVATAR_SIZE } from '~/components/MainNavigation/WorkspacesNav/WorkspacesNavImage';
import { WorkspaceNavListItem } from '~/components/MainNavigation/WorkspacesNav/WorkspacesNavListItem';
import DragType, { DragContainerIds, DragWorkspaceItem, RearrangableItem } from '~/components/Shared/Drag/dragTypes';
import { accountSettingsRoutes, Routes, workspaceRoutes } from '~/constants/routes';
import { useSwitchWorkspace } from '~/hooks/useSwitchWorkspace';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useRearrangeWorkspacePosition } from '~/swr-hooks/workspaces/useRearrangeWorkspacePosition';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';

// return false if element is at the same position
const canDropItem = (toIndex: number) => (item: DragWorkspaceItem) =>
  !(item.index === toIndex || item.index === toIndex + 1);

export const WorkspaceNav = memo(() => {
  const { switchWorkspace } = useSwitchWorkspace();
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: workspaces = [] } = useWorkspaces();
  const { rearrangeWorkspacePosition } = useRearrangeWorkspacePosition();
  const { trackCompletedDragging } = useTrackCompletedDragging();

  const getRowHeight = useCallback(
    ({ index }: { index: number }) => {
      if (index === 0) {
        // first item is just a drop indicator
        return 12;
      } else if (index === workspaces.length) {
        // last item must be a bit higher to show full rearrange indicator
        return WORKSPACE_NAV_IMAGE_AVATAR_SIZE + 14;
      } else {
        return WORKSPACE_NAV_IMAGE_AVATAR_SIZE + 12;
      }
    },
    [workspaces.length],
  );

  const onWorkspaceRearrange = useCallback(
    (toIndex: number) => (item: DragWorkspaceItem) => {
      const adjacentItem: RearrangableItem = {
        direction: 'after',
        index: toIndex,
      };

      trackCompletedDragging({
        workspaceId: item.id,
        newIndex: toIndex,
        dropLocation: {
          type: 'workspace-rearrange',
          id: item.id,
        },
      });

      rearrangeWorkspacePosition(item, adjacentItem);
    },
    [rearrangeWorkspacePosition, trackCompletedDragging],
  );

  const renderWorkspace: ListRowRenderer = useCallback(
    ({ index, key, style }) => {
      const workspace = workspaces[index - 1];

      let Component;

      if (index === 0) {
        Component = (
          <DropRearrangeArea
            dropLocation="workspace-rearrange"
            onItemRearrange={onWorkspaceRearrange(-1)}
            dragTypes={[DragType.workspace]}
            canDropItem={canDropItem(-1)}
            containerProps={{ style: { width: WORKSPACE_NAV_IMAGE_AVATAR_SIZE, left: 10, marginTop: 4 } }}
          />
        );
      } else {
        const workspaceIndex = index - 1;
        const isActive = workspace.id === currentWorkspace?.id;

        Component = (
          <WorkspaceNavListItem
            workspace={workspace}
            index={index - 1}
            onItemRearrange={onWorkspaceRearrange(workspaceIndex)}
            canDropItem={canDropItem(workspaceIndex)}
            onClick={() => {
              if (!isActive) {
                switchWorkspace(workspace);
              }
              const shouldRedirect =
                !workspaceRoutes.includes(Router.pathname) && !accountSettingsRoutes.includes(Router.pathname);
              if (shouldRedirect) {
                Router.push(Routes.home);
              }
            }}
            isActive={isActive}
          />
        );
      }

      return (
        <div key={key} style={{ ...style }}>
          {Component}
        </div>
      );
    },
    [workspaces, onWorkspaceRearrange, currentWorkspace?.id, switchWorkspace],
  );

  return (
    <div className="relative flex h-full flex-col pb-12">
      <AutoSizer>
        {({ height, width }) => (
          <Box
            as={List}
            tx={{
              pb: 70,
              pt: 7,
              'scrollbar-width': 'none' /* Firefox */,
              '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
              '::-webkit-scrollbar': {
                /* Chrome */
                display: 'none',
              },
            }}
            style={{
              overflowX: 'auto',
            }}
            id={DragContainerIds['workspace']}
            width={width}
            height={height}
            // workspaces + drop area before first item
            rowCount={workspaces.length + 1}
            rowHeight={getRowHeight}
            rowRenderer={renderWorkspace}
          />
        )}
      </AutoSizer>
      <div className="pointer-events-none absolute bottom-12 h-20 w-full shrink-0 bg-gradient-to-b from-transparent to-grey-1" />
    </div>
  );
});

WorkspaceNav.displayName = 'WorkspaceNav';
