import { Form, FormFieldType, ShortUrlResponse } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { memo } from 'react';

import { ContentCollectionRequireAccountToggle } from '~/components/Modals/ContentCollectionModal/ContentCollectionRequireAccountToggle';
import { MenuAction } from '~/components/UI/CopyField';
import { getPublicBoardCustomFieldsKey } from '~/constants/react-query-keys';
import { useFormPermissions } from '~/hooks/useFormPermissions';
import { useShortUrlPermissions } from '~/hooks/useShortUrlPermissions';
import { EditShareLinkView } from '~/types/ShareLink';
import { canCreateShortUrl, canDeleteShortUrl } from '~/utils/permissions/shortUrlPermissions';

import { ContentCollectionCopyLink } from './ContentCollectionCopyLink';
import { CustomFieldsSection } from './CustomFieldsSection';
import { FormDescription } from './FormDescription';
import { FormTitle } from './FormTitle';
import { SettingsItem } from './SettingsItem';

//currently this modal is only ever opened from the share button in a board header
const trackLocation = 'header';

type ContentCollectionEditActionsProps = {
  form: Form;
  shortIdInfo: ShortUrlResponse;
  setView: (view: EditShareLinkView) => void;
};

export const ContentCollectionEditActions = memo(
  ({ form, shortIdInfo, setView }: ContentCollectionEditActionsProps) => {
    const queryClient = useQueryClient();
    const _fields = form.fields;
    const formFields = _fields?.filter((field) => field.type !== FormFieldType.customField);
    const { formPermissions } = useFormPermissions({
      formId: form.id,
    });

    const { shortUrlPermissions } = useShortUrlPermissions({ shortId: shortIdInfo.id });

    const menuActions: MenuAction[] = [];

    if (canDeleteShortUrl(shortUrlPermissions)) {
      menuActions.push({
        text: 'Delete link',
        action: () => setView('delete'),
      });

      if (canCreateShortUrl(formPermissions)) {
        menuActions.push({
          text: 'Generate a new link',
          action: () => setView('regenerate'),
        });
      }
    }

    return (
      <>
        <div className="mb-8 mt-2 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <FormTitle form={form} />
            <FormDescription form={form} />
          </div>
          <div className="flex flex-col gap-2">
            <ContentCollectionCopyLink shortIdInfo={shortIdInfo} menuActions={menuActions} />
            <ContentCollectionRequireAccountToggle boardId={form.boardId} formId={form.id} shortIdInfo={shortIdInfo} />
          </div>
        </div>
        <div className="mb-6 gap-1">
          <p className="text-12 font-bold uppercase text-pigeon-400">Form Fields</p>
          <span className="text-14 text-pigeon-600">
            Add fields to your form so visitors can categorize their uploads. Require certain fields, or leave them
            optional.
          </span>
        </div>
        <div className="mt-3">
          {formFields?.map((formField) => <SettingsItem form={form} key={formField.id} formField={formField} />)}
        </div>
        <CustomFieldsSection
          form={form}
          fields={_fields}
          onCreate={() => queryClient.invalidateQueries(getPublicBoardCustomFieldsKey(form.boardId, shortIdInfo.id))}
          trackLocation={trackLocation}
        />
      </>
    );
  },
);

ContentCollectionEditActions.displayName = 'ContentCollectionEditActions';
