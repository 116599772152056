import { CustomFields } from '@air/api';
import { CustomFieldColor, CustomFieldListResponse } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CustomFieldModalFormData } from '~/components/CustomFields/CustomFieldModal/CustomFieldModalForm/types';
import { getConfigViewOptionsKey, getWorkspaceCustomFieldsKey } from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { useCanSeePrivateIPTCFields } from '~/swr-hooks/workspaces/addOns/useCanSeePrivateIPTCFields';
import { createEmptyListResponseStructure } from '~/utils/ApiUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export const useCreateWorkspaceCustomField = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const store = useAirStore();

  const { canSeePrivateIPTCFields } = useCanSeePrivateIPTCFields();

  const createWorkspaceCustomField = useMutation({
    mutationFn: ({ formValues, colors }: { formValues: CustomFieldModalFormData; colors: Array<CustomFieldColor> }) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return CustomFields.createCustomField({
        workspaceId,
        description: formValues.description,
        fieldType: {
          id: formValues.fieldTypeId,
        },
        name: formValues.name,
        values: formValues.values?.map((v) => {
          return {
            color: {
              id: colors.find((c) => c.backgroundHex === v.color.backgroundHex)!.id,
            },
            value: v.value,
          };
        }),
      });
    },

    onMutate: async () => {
      if (!currentWorkspace?.id) {
        return;
      }

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(getWorkspaceCustomFieldsKey(currentWorkspace.id));

      // Snapshot the previous value
      const previousCustomFields = queryClient.getQueryData<CustomFieldListResponse>(
        getWorkspaceCustomFieldsKey(currentWorkspace.id),
      );

      // Return a context object with the snapshotted value
      return { previousCustomFields };
    },
    onSuccess: (newCustomField) => {
      if (!currentWorkspace?.id) {
        return;
      }

      // Optimistically update to the new value
      queryClient.setQueryData<CustomFieldListResponse | undefined>(
        getWorkspaceCustomFieldsKey(currentWorkspace.id),
        (cache) =>
          cache
            ? {
                ...cache,
                data: [newCustomField, ...cache.data],
              }
            : {
                ...createEmptyListResponseStructure(),
                total: 1,
                data: [newCustomField],
              },
      );

      const viewType = currentViewTypeNameSelector(store.getState());

      queryClient.invalidateQueries(
        getConfigViewOptionsKey({
          workspaceId: currentWorkspace.id,
          viewType,
          canSeeIPTCFields: canSeePrivateIPTCFields,
        }),
      );
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (error, { formValues, colors }, context) => {
      if (!currentWorkspace?.id) {
        return;
      }

      reportErrorToBugsnag({
        error,
        context: 'Failed to create custom field',
        metadata: {
          formValues,
          colors,
        },
      });

      queryClient.setQueryData(getWorkspaceCustomFieldsKey(currentWorkspace.id), context?.previousCustomFields);
    },
  });

  return { createWorkspaceCustomField };
};
