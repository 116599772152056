import { Importer } from '@air/api';
import { ClipSource, ImporterListItem, ImporterListResponse, ImportStatus } from '@air/api/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const IMPORTS = 'IMPORTS';

export const getImportsKey = (workspaceId?: string) => [IMPORTS, { workspaceId }];

export const getImportsInProgress = (imports: ImporterListItem[] = []) =>
  imports.filter(({ status }) => status === ImportStatus.inProgress || status === ImportStatus.created);

export const useImports = ({ refetchInterval }: { refetchInterval: number }) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const workspaceId = currentWorkspace?.id;

  const { data: imports } = useQuery<ImporterListResponse>(
    getImportsKey(workspaceId),
    () => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return Importer.list({
        workspaceId,
        params: {
          limit: 1000,
          sources: [ClipSource.dropbox, ClipSource.googleDrive, ClipSource.box],
        },
      });
    },
    {
      refetchInterval: !refetchInterval ? false : refetchInterval,
      enabled: !!workspaceId,
    },
  );

  return {
    imports,
    hasImportsInProgress: getImportsInProgress(imports?.data).length > 0,
  };
};

export const useRefreshImports = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();
  const workspaceId = currentWorkspace?.id;

  const refreshImports = useCallback(
    () => queryClient.invalidateQueries(getImportsKey(workspaceId)),
    [queryClient, workspaceId],
  );

  return {
    refreshImports,
  };
};
