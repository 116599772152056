import { useTrackInvitedLibraryMember } from '@air/analytics';
import { AddLibraryMemberInput, Libraries } from '@air/api';
import type { Library } from '@air/api/types';
import { useMutation } from '@tanstack/react-query';

import { getLibrariesKey } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { getLibraryMembersKey, isLibraryNonWorkspaceMember } from '~/components/LibraryBeta/hooks/useLibraryMembers';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getWorkspaceMembersKey } from '~/swr-hooks/members/useGetWorkspaceMembers';
import { queryClient } from '~/swr-hooks/queryClient';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type UseLibraryMembersAddMutationParams = {
  libraryId: Library['id'];
};

export const useLibraryMembersAddMutation = ({ libraryId }: UseLibraryMembersAddMutationParams) => {
  const { trackInvitedLibraryMember } = useTrackInvitedLibraryMember();
  const { currentWorkspace } = useCurrentWorkspace();

  const libraryMembersAddMutation = useMutation(
    (data: AddLibraryMemberInput[]) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspaceId');
      }
      return Libraries.addMembers({ workspaceId, libraryId, members: data });
    },
    {
      onError: (error, variables) => {
        reportErrorToBugsnag({
          error,
          context: 'Failed to invite members to library',
          metadata: {
            data: {
              libraryId,
              user: variables,
            },
          },
        });
      },
      onSuccess: (_data, variables) => {
        variables.forEach((user) => {
          trackInvitedLibraryMember({
            library_id: libraryId,
            library_member_email: user.email,
            library_member_role_id: user.roleId,
          });
        });

        queryClient.invalidateQueries(getLibrariesKey(currentWorkspace?.id));
        queryClient.invalidateQueries(getLibraryMembersKey(libraryId));

        const hasNonWorkspaceMembers = _data.some((member) => isLibraryNonWorkspaceMember(member));
        if (hasNonWorkspaceMembers && currentWorkspace) {
          queryClient.invalidateQueries(getWorkspaceMembersKey(currentWorkspace.id));
        }
      },
    },
  );

  return { libraryMembersAddMutation };
};
