import { useTrackApprovedLibraryAccess } from '@air/analytics';
import { Libraries, UpdateLibraryMemberInput } from '@air/api';
import { Library, LibraryMemberWithInfo } from '@air/api/types';
import { useMutation } from '@tanstack/react-query';

import { getLibraryMembersKey } from '~/components/LibraryBeta/hooks/useLibraryMembers';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { queryClient } from '~/swr-hooks/queryClient';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type UseLibraryApproveMemberMutationParams = {
  libraryId: Library['id'];
};

export const useLibraryApproveMemberMutation = ({ libraryId }: UseLibraryApproveMemberMutationParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackApprovedLibraryAccess } = useTrackApprovedLibraryAccess();

  const libraryMemberApproveMutation = useMutation(
    (data: Pick<Required<UpdateLibraryMemberInput>, 'accountId'>) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.updateMember({ workspaceId, libraryId, member: { ...data, pending: false } });
    },
    {
      onMutate: (variables) => {
        const previousLibraryMembers = queryClient.getQueryData<LibraryMemberWithInfo[]>(
          getLibraryMembersKey(libraryId),
        );

        queryClient.cancelQueries(getLibraryMembersKey(libraryId));

        if (previousLibraryMembers) {
          queryClient.setQueryData<LibraryMemberWithInfo[]>(
            getLibraryMembersKey(libraryId),
            previousLibraryMembers.map((member) =>
              member.accountId === variables.accountId ? { ...member, ...variables } : member,
            ),
          );
        }

        return {
          previousLibraryMembers,
        };
      },

      onError: (error, variables, context) => {
        if (context) {
          queryClient.setQueryData(getLibraryMembersKey(libraryId), context.previousLibraryMembers);
        }

        reportErrorToBugsnag({
          error,
          context: 'Unable to approve library member',
          metadata: {
            key: 'data',
            data: {
              libraryId,
              member: { ...variables },
            },
          },
        });
      },

      onSuccess(_data, variables) {
        trackApprovedLibraryAccess({
          library_id: libraryId,
          library_member_id: variables.accountId,
        });

        queryClient.invalidateQueries(getLibraryMembersKey(libraryId));
      },
    },
  );

  return { libraryMemberApproveMutation };
};
