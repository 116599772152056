import { AddOns } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { ONE_HOUR } from '~/constants/unitsOfTime';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const getWorkspaceAddOnsKey = (workspaceId: string | undefined) => ['WORKSPACE_ADDONS', workspaceId];

export const useCurrentWorkspaceAddOns = ({ enabled }: { enabled: boolean }) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const key = getWorkspaceAddOnsKey(currentWorkspace?.id);

  return useQuery(
    key,
    async () => {
      invariant(currentWorkspace?.id, 'No current workspace');

      return AddOns.list({
        workspaceId: currentWorkspace.id,
      });
    },
    {
      staleTime: ONE_HOUR,
      enabled: enabled && !!currentWorkspace?.id,
    },
  );
};
