import { Subscriptions } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

export const SUBSCRIPTION_UPCOMING_INVOICE = 'SUBSCRIPTION_UPCOMING_INVOICE';

export const getSubscriptionUpcomingInvoiceKey = (workspaceId?: string) => {
  return workspaceId ? [SUBSCRIPTION_UPCOMING_INVOICE, { workspaceId }] : [SUBSCRIPTION_UPCOMING_INVOICE];
};

export const useUpcomingInvoice = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { data: subscription } = useSubscriptionContext();
  const planId = subscription?.planId;

  return useQuery(
    getSubscriptionUpcomingInvoiceKey(workspaceId),
    () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Subscriptions.retrieveUpcomingInvoice({ workspaceId });
    },
    {
      enabled: !!workspaceId && !!planId && canManageSubscription(workspacePermissions),
    },
  );
};
