import { Box, BoxProps } from '@air/zephyr';

import noNotifications from '~/assets/no-notifications.png';

interface EmptyNotificationsProps extends Omit<BoxProps<'img'>, 'src'> {
  size?: number | number[];
}

const EmptyNotifications = ({ size = 96, ...props }: EmptyNotificationsProps) => (
  <Box src={noNotifications.src} {...props} tx={{ width: size, height: size }} as="img" alt="Illustration of a cat" />
);

export default EmptyNotifications;
