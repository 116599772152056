import { type Workspace } from '@air/api/types';
import { type QueryObserverOptions, useInfiniteQuery } from '@tanstack/react-query';
import { isObject } from 'lodash';

import { useRecentlyDeletedTableViewItems } from '~/components/RecentlyDeleted/hooks/useRecentlyDeletedTableViewItems';
import { recentlyDeletedFetcher } from '~/components/RecentlyDeleted/temp/fetcher';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const RECENTLY_DELETED_KEY = 'RECENTLY_DELETED';

/**
 * @todo - Update this when BE finishes the API
 */
export type RecentlyDeletedOptions = {};

export const RECENTLY_DELETED_DEFAULT_OPTIONS: RecentlyDeletedOptions = {};

export type RecentlyDeletedKey = [typeof RECENTLY_DELETED_KEY, RecentlyDeletedOptions];

export const getRecentlyDeletedKey = (
  workspaceId?: Workspace['id'],
  options?: RecentlyDeletedOptions,
): RecentlyDeletedKey => [
  RECENTLY_DELETED_KEY,
  {
    workspaceId,
    ...RECENTLY_DELETED_DEFAULT_OPTIONS,
    ...options,
  },
];

export const isRecentlyDeletedKey = (key: unknown): key is RecentlyDeletedKey => {
  return Array.isArray(key) && key[0] === RECENTLY_DELETED_KEY && isObject(key[1]);
};

export type UseRecentlyDeletedParams = Pick<QueryObserverOptions, 'enabled'> & {
  key?: RecentlyDeletedKey;
  options?: RecentlyDeletedOptions;
};

export const useRecentlyDeleted = (params?: UseRecentlyDeletedParams) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isInitialLoading } = useInfiniteQuery(
    getRecentlyDeletedKey(currentWorkspace?.id, params?.options),
    ({ pageParam }) => recentlyDeletedFetcher({ cursor: pageParam }),
    {
      enabled: params?.enabled && !!currentWorkspace?.id,
      keepPreviousData: false,
      getNextPageParam: (prevPage) => prevPage.pagination.cursor,
    },
  );

  return useRecentlyDeletedTableViewItems({
    data: data?.pages ?? [],
    fetchNextPage,
    hasNextPage: !!hasNextPage,
    isFetchingNextPage,
    isInitialLoading,
  });
};
