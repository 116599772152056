import { Notifications } from '@air/api';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

import { getNotificationSummaryKey } from './useGetNotificationSummary';

export const useResetUnviewedCount = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const resetUnviewedCount = useCallback(async () => {
    const workspaceId = currentWorkspace?.id;
    if (!workspaceId) {
      return;
    }

    const summaryKey = getNotificationSummaryKey(workspaceId);
    queryClient.setQueriesData(summaryKey, { unseen: 0 });
    await Notifications.resetNotificationSummary({ workspaceId });
    queryClient.invalidateQueries(summaryKey);
  }, [currentWorkspace?.id, queryClient]);

  return {
    resetUnviewedCount,
  };
};
