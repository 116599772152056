import { useTrackDeletedItems } from '@air/analytics';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { DeleteLibraryCompletedPanelItem } from '~/components/DeleteLibraryTasks/DeleteLibraryTaskCompletedPanelItem';
import { DeleteLibraryFailedPanelItem } from '~/components/DeleteLibraryTasks/DeleteLibraryTaskFailedPanelItem';
import { DeleteLibraryPanelInProgressPanelItem } from '~/components/DeleteLibraryTasks/DeleteLibraryTaskInProgressPanelItem';
import { PaneContainer } from '~/components/FileStatusTrackingPane/PaneContainer';
import { isLibariesKey } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { getLibraryKey } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { getRecentlyDeletedKey } from '~/components/RecentlyDeleted/hooks/queries/useRecentlyDeleted';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSocketConnectionChange } from '~/providers/SocketContext/hooks/useSocketConnectionChange';
import { removeTasksAction } from '~/store/tasks/actions';
import { makeLibraryDeletionTasksSelectorByWorkspaceId } from '~/store/tasks/selectors';
import { queryClient } from '~/swr-hooks/queryClient';
import { ReadyState } from '~/types/sockets';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useAirSelector } from '~/utils/ReduxUtils';
import { useCancelTask } from '~/utils/taskUtils/useCancelTask';
import { useHideTask } from '~/utils/taskUtils/useHideTask';
import { usePrivateSyncTasks } from '~/utils/taskUtils/usePrivateSyncTasks';

export const PrivateDeleteLibraryTasksPanel = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const tasksSelector = useMemo(
    () => makeLibraryDeletionTasksSelectorByWorkspaceId(currentWorkspace?.id),
    [currentWorkspace?.id],
  );
  const dispatch = useDispatch();
  const libraryDeletionTasks = useAirSelector(tasksSelector);
  const { cancelTask } = useCancelTask();
  const { hideTask } = useHideTask();
  const { trackDeletedItems } = useTrackDeletedItems();
  const recentlyDeleteKey = getRecentlyDeletedKey(currentWorkspace?.id);

  const { loadFromStorage, syncLocalTasks } = usePrivateSyncTasks({
    workspaceId: currentWorkspace?.id,
    tasksSelector,
    localType: 'LibraryDeletion',
    remoteType: 'ContentRemover',
    onError({ localTask }) {
      reportErrorToBugsnag({
        error: new Error('Library deletion task failed. Task ID: ' + localTask.remoteTaskId),
        context: 'Unable to delete library',
        metadata: {
          key: 'data',
          data: localTask.metadata,
        },
      });
    },
    onComplete: async ({ localTask, enrichMetadata }) => {
      const { libraryId } = localTask.metadata;

      trackDeletedItems({ item_ids: [libraryId], item_type: 'library', item_total: 1 });

      queryClient.invalidateQueries(recentlyDeleteKey);

      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return isLibariesKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
        },
      });

      queryClient.invalidateQueries(getLibraryKey(libraryId));

      enrichMetadata({
        deletedLibraryId: libraryId,
      });
    },
  });

  /**
   * On mount, take the tasks from local storage and add them to Redux
   */
  useEffect(() => {
    if (currentWorkspace?.id) {
      loadFromStorage();
    }
  }, [loadFromStorage, currentWorkspace?.id]);

  /**
   * Because sockets reconnect when the user's internet connection comes back online,
   * we don't need an explicit isOnline check but can piggyback off of the socket reconnecting
   */
  useSocketConnectionChange({
    onChange: (readyState) => {
      if (readyState === ReadyState.OPEN) {
        syncLocalTasks();
      }
    },
  });

  /**
   * When this panel unmounts (user logs out most likely),
   * reset the state
   */
  useUnmount(() => {
    dispatch(
      removeTasksAction({
        taskIds: libraryDeletionTasks.map(({ localTaskId }) => localTaskId),
      }),
    );
  });

  if (libraryDeletionTasks.length < 1) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      {libraryDeletionTasks.map((libraryDeletionTask) => {
        return (
          <PaneContainer key={libraryDeletionTask.localTaskId}>
            {!libraryDeletionTask.hidden && libraryDeletionTask.status === 'in-progress' && (
              <DeleteLibraryPanelInProgressPanelItem
                onCancel={() => hideTask(libraryDeletionTask.localTaskId)}
                {...libraryDeletionTask}
              />
            )}
            {libraryDeletionTask.status === 'completed' && (
              <DeleteLibraryCompletedPanelItem
                onClear={() => cancelTask(libraryDeletionTask.localTaskId)}
                {...libraryDeletionTask}
              />
            )}
            {libraryDeletionTask.status === 'error' && (
              <DeleteLibraryFailedPanelItem
                onClear={() => cancelTask(libraryDeletionTask.localTaskId)}
                {...libraryDeletionTask}
              />
            )}
          </PaneContainer>
        );
      })}
    </div>
  );
});

PrivateDeleteLibraryTasksPanel.displayName = 'PrivateDeleteLibraryTasksPanel';
