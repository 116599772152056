import { memo, PropsWithChildren, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';

import { makeStore } from '~/store';
import { resetPermissionsAction } from '~/store/permissions/actions';

export interface PrivateReduxProviderProps {}

export const PrivateReduxProvider = memo(({ children }: PropsWithChildren<PrivateReduxProviderProps>) => {
  const { store } = useMemo(() => {
    return makeStore();
  }, []);

  useEffect(() => {
    return () => {
      store.dispatch(resetPermissionsAction());
    };
  }, [store]);

  return <Provider store={store}>{children}</Provider>;
});

PrivateReduxProvider.displayName = 'PrivateReduxProvider';
