import { tailwindMerge, tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { Slot } from '@radix-ui/react-slot';
import {
  cloneElement,
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
  isValidElement,
  memo,
  ReactElement,
  ReactNode,
} from 'react';

export const SIDEBAR_NAVIGATION_ITEM_ELEMENT_TAG = 'div';

export const SIDEBAR_NAVIGATION_ITEM_TEST_ID = 'SIDEBAR_NAVIGATION_ITEM';

export const sidebarNavigationItem = tailwindVariants({
  base: 'relative z-0 flex h-8 w-full cursor-pointer items-center gap-2 rounded px-2 text-left text-14 text-grey-10 transition-colors hover:no-underline',
  variants: {
    state: {
      default: 'bg-transparent hover:bg-grey-3',
      active: 'bg-grey-3 font-medium hover:bg-grey-5',
    },
  },
  defaultVariants: {
    state: 'default',
  },
});

export type SidebarNavigationItemsVariants = VariantProps<typeof sidebarNavigationItem>;

export type SidebarNavigationItemProps = Omit<
  ComponentPropsWithoutRef<typeof SIDEBAR_NAVIGATION_ITEM_ELEMENT_TAG>,
  'prefix'
> &
  SidebarNavigationItemsVariants &
  (
    | {
        asChild?: false;
        children: ReactNode;
      }
    | {
        asChild?: true;
        children: ReactElement<any>;
      }
  ) &
  Pick<
    ComponentProps<'button'>,
    | 'onClick'
    | 'onMouseDown'
    | 'onMouseEnter'
    | 'onMouseLeave'
    | 'onMouseMove'
    | 'onMouseOut'
    | 'onMouseOver'
    | 'onMouseUp'
  > & {
    buttonClassName?: string;
    prefix?: ReactNode;
    prefixClassName?: string;
    suffix?: ReactNode;
    suffixClassName?: string;
  };

export const SidebarNavigationItem = memo(
  forwardRef<HTMLButtonElement | any, SidebarNavigationItemProps>(
    (
      {
        asChild,
        buttonClassName,
        children,
        className,
        onClick,
        onMouseDown,
        onMouseEnter,
        onMouseLeave,
        onMouseMove,
        onMouseOut,
        onMouseOver,
        onMouseUp,
        prefix,
        prefixClassName,
        state,
        suffix,
        suffixClassName,
        ...restOfProps
      },
      forwardedRef,
    ) => {
      const Component: any = asChild ? Slot : SIDEBAR_NAVIGATION_ITEM_ELEMENT_TAG;

      return (
        <div
          className={sidebarNavigationItem({ state, class: className })}
          data-testid={SIDEBAR_NAVIGATION_ITEM_TEST_ID}
          ref={forwardedRef}
          {...restOfProps}
        >
          {!!prefix && <div className={tailwindMerge('shrink-0 cursor-auto', prefixClassName)}>{prefix}</div>}

          <Component
            className={tailwindMerge(
              'border-box flex h-full w-full min-w-0 items-center justify-start gap-2 truncate text-inherit after:absolute after:inset-0 after:-z-1 after:h-full after:w-full after:content-[""] hover:no-underline',
              buttonClassName,
            )}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            onMouseUp={onMouseUp}
          >
            {asChild && isValidElement<any>(children)
              ? cloneElement(children, {
                  children: children.props.children,
                })
              : children}
          </Component>

          {!!suffix && <div className={tailwindMerge('shrink-0 cursor-auto', suffixClassName)}>{suffix}</div>}
        </div>
      );
    },
  ),
);

SidebarNavigationItem.displayName = 'SidebarNavigationItem';
