import { trackGTMEvent, useTrackFailedToUploadAsset, useTrackUploadedAsset } from '@air/analytics';
import { Clips } from '@air/api';
import { ClipSource } from '@air/api/types';
import { isLarge, uploadByIdSelector } from '@air/redux-uploader';
import { useUploadManager, UseUploadManagerProps } from '@air/upload-manager';
import { isAbortError } from '@air/upload-utils';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { PrivateUploadTaskParams, UploadTaskParams } from '~/components/Upload/hooks/types';
import { useNavigateToNewVersionOnUpload } from '~/components/Upload/hooks/useNavigateToNewVersionOnUpload';
import { useFetchClipForPrivateUpload } from '~/components/Upload/privateUpload/useFetchClipForPrivateUpload';
import { useGetLargePrivateUploadTasks } from '~/components/Upload/privateUpload/useGetLargePrivateUploadTasks';
import { useGetSmallPrivateUploadTask } from '~/components/Upload/privateUpload/useGetSmallPrivateUploadTask';
import { centralizedClipSelector } from '~/store/centralizedClip/selectors';
import {
  metadataForPrivateUploadSelector,
  privateUploadBoardIdSelector,
} from '~/store/privateUploadMetadata/selectors';
import { useStorage } from '~/swr-hooks/subscriptions/useStorage';
import { defaultClipGetOptions, getClipWithoutWorkspaceIdKey } from '~/swr-hooks/useGetClipWithNoWorkspaceId';
import { getAssetGalleryItemType } from '~/utils/AssetUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useAddBoardsToAllLists } from '~/utils/mutateUtils/AllBoards';
import { useAddAssetsToAllViews, useUpdateVersionByAssetId } from '~/utils/mutateUtils/GalleryAssets';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export const usePrivateUploadManager = () => {
  const store = useAirStore();
  const { data: storage } = useStorage();
  const { addBoardsToAllLists } = useAddBoardsToAllLists();
  const { addAssetsToAllViews } = useAddAssetsToAllViews();
  const { updateVersionByAssetId } = useUpdateVersionByAssetId();
  const client = useQueryClient();
  const { fetchClipForPrivateUpload } = useFetchClipForPrivateUpload();
  const { trackUploadedAsset } = useTrackUploadedAsset();
  const { trackFailedToUploadAsset } = useTrackFailedToUploadAsset();

  const { getSmallUploadTask } = useGetSmallPrivateUploadTask();
  const { getLargeUploadTasks } = useGetLargePrivateUploadTasks();

  const { navigateToNewVersionOnUpload } = useNavigateToNewVersionOnUpload();

  const getUploadTasks = useCallback<UseUploadManagerProps['getUploadTasks']>(
    ({ upload }) => {
      const onError: Required<UploadTaskParams>['onError'] = ({ error, upload }) => {
        const _error = reportErrorToBugsnag({ error, context: 'Error uploading file' });
        if (!isAbortError(_error)) {
          trackFailedToUploadAsset({
            id: upload.id,
            type: upload.apiUploadData.type,
            source: ClipSource.web,
            error: _error.message,
          });
        }
      };

      const onUploadFinished: Required<UploadTaskParams>['onUploadFinished'] = async ({ uploadId }) => {
        const upload = uploadByIdSelector(store.getState(), uploadId);

        if (!upload) {
          return;
        }

        /**
         * This shouldn't be checked on the frontend. It should be checked on the backend.
         */
        if (storage?.usedStorage === 0) {
          trackGTMEvent({ event: 'first_upload' });
        }

        trackUploadedAsset({ clipId: upload.clipId });
        const uploadParentBoardId = privateUploadBoardIdSelector(store.getState(), upload.id);
        const currentBoardId = getBoardIdFromPath(window.location.pathname);
        const boardId = currentBoardId && uploadParentBoardId ? uploadParentBoardId : undefined;

        if (upload.clipId) {
          const uploadMetadata = metadataForPrivateUploadSelector(store.getState(), upload.id);
          const clipGetOptions = { ...defaultClipGetOptions, boardId };
          const clipCacheKey = getClipWithoutWorkspaceIdKey({ clipId: upload.clipId, options: clipGetOptions });
          const clip = await client.ensureQueryData(clipCacheKey, () =>
            Clips.getWithNoWorkspaceId({ id: upload.clipId, options: clipGetOptions }),
          );

          if (clip) {
            updateVersionByAssetId(clip, boardId);
            if (!uploadMetadata?.isVersion) {
              await addAssetsToAllViews({
                parentBoardId: boardId,
                assets: [clip],
                type: getAssetGalleryItemType(clip),
              });
            }
          }
        }
      };

      const params: PrivateUploadTaskParams = {
        onBoardCreated: (board) => addBoardsToAllLists([board], board.parentId),
        onUploadFinished,
        uploadId: upload.id,
        onUploadStarted: async ({ uploadId }) => {
          const clip = await fetchClipForPrivateUpload(uploadId);

          if (clip) {
            const centralizedClip = centralizedClipSelector(store.getState());
            if (clip.assetId === centralizedClip.assetId) {
              navigateToNewVersionOnUpload(clip);
            }
          }
        },
        onError,
      };

      if (isLarge(upload)) {
        return getLargeUploadTasks(params);
      } else {
        return [getSmallUploadTask(params)];
      }
    },
    [
      addAssetsToAllViews,
      addBoardsToAllLists,
      client,
      fetchClipForPrivateUpload,
      getLargeUploadTasks,
      getSmallUploadTask,
      navigateToNewVersionOnUpload,
      storage?.usedStorage,
      store,
      trackFailedToUploadAsset,
      trackUploadedAsset,
      updateVersionByAssetId,
    ],
  );

  return useUploadManager({
    getUploadTasks,
  });
};
