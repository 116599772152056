import { Form, FormFieldType } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { useQueryClient } from '@tanstack/react-query';
import { memo, useState } from 'react';

import { useCreateForm } from '~/components/Modals/ContentCollectionModal/hooks/useCreateForm';
import { useGetShortIdForForm } from '~/components/Modals/ContentCollectionModal/hooks/useGetShortIdForForm';
import { SharePrivateItemCreateLink } from '~/components/SharePrivateItemCreateLink';
import { getContentCollectionFormsKey } from '~/swr-hooks/forms/useListForms';

interface CreateContentCollectionLinkProps {
  boardId: string;
  boardTitle: string;
  form: Form | undefined;
}

export const CreateContentCollectionLink = memo(({ boardId, boardTitle, form }: CreateContentCollectionLinkProps) => {
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState(false);
  const { showToast } = useToasts();
  const { getShortIdForForm } = useGetShortIdForForm();
  const { createForm } = useCreateForm();

  const createShortId = async () => {
    try {
      setIsCreating(true);

      /**
       * We check if a form already exists because the user could've created a form (and short url link)
       * before but then deleted link and is trying to create a new one but we can't
       * create a new form so we check to make sure one isn't already created
       */
      if (!form) {
        form = await createForm.mutateAsync({
          form: {
            boardId,
            title: boardTitle,
            fields: [
              { required: true, type: FormFieldType.title },
              { required: false, type: FormFieldType.description },
            ],
          },
        });
      }

      await getShortIdForForm.mutateAsync({
        id: form.id,
        boardContext: undefined,
      });

      queryClient.invalidateQueries(getContentCollectionFormsKey(boardId));

      setIsCreating(false);
    } catch (error) {
      setIsCreating(false);
      showToast(`There was an error when trying to create the link. Please try again later.`);
    }
  };

  return (
    <SharePrivateItemCreateLink
      isCreating={isCreating}
      onCreate={createShortId}
      itemName="Form"
      description="No form available"
    />
  );
});

CreateContentCollectionLink.displayName = 'CreateContentCollectionLink';
