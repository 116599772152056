import { Forms } from '@air/api';
import { Form, FormsListResponse, UpdateFormInput } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getContentCollectionFormsKey } from './useListForms';

export const useUpdateForm = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const updateForm = useCallback(
    async (params: UpdateFormInput & Pick<Form, 'boardId'>) => {
      const updateCache = (formsData: FormsListResponse | undefined) => {
        if (!formsData) {
          return;
        }
        return {
          ...formsData,
          data: params ? ([params] as Form[]) : formsData.data,
        };
      };

      if (!workspaceId) {
        throw new Error('Workspace ID is not defined');
      }

      try {
        const cacheKey = getContentCollectionFormsKey(params.boardId);

        queryClient.setQueryData<FormsListResponse | undefined>(cacheKey, updateCache);
        await Forms.updateForm({
          workspaceId,
          form: params,
        });
        queryClient.invalidateQueries(cacheKey);
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to update content collection form',
          metadata: { data: { params } },
        });
      }
    },
    [queryClient, workspaceId],
  );

  return { updateForm };
};
