import { Libraries } from '@air/api';
import { Library, type LibraryWithPermissions } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const LIBRARY_QUERY_KEY = 'LIBRARY';

export const getLibraryKey = (libraryId?: string) => [LIBRARY_QUERY_KEY, { libraryId }];

export type UseLibraryParams = {
  initialData?: LibraryWithPermissions;
  libraryId: Library['id'] | undefined;
};

export const useLibrary = ({ initialData, libraryId }: UseLibraryParams) => {
  const { currentWorkspace } = useCurrentWorkspace();

  return useQuery(
    getLibraryKey(libraryId),
    () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.get({ workspaceId, libraryId: libraryId! });
    },
    {
      enabled: !!currentWorkspace?.id && !!libraryId,
      initialData,
    },
  );
};
