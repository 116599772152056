import { ShortUrlResponse } from '@air/api/types';
import { merge } from 'lodash';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { UpdateShortIdParams, useUpdateShortId } from '~/swr-hooks/shortUrl/useUpdateShortId';

interface UseUpdateContentCollectionLinkParams {
  formId: string;
  shortIdInfo: ShortUrlResponse;
}

export const useUpdateContentCollectionLink = ({ formId, shortIdInfo }: UseUpdateContentCollectionLinkParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { updateShortId } = useUpdateShortId();

  const onShortUrlUpdate = useCallback(
    async (props: DeepPartial<Omit<UpdateShortIdParams, 'objectId' | 'id' | 'boardContext' | 'shortId'>>) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      await updateShortId.mutateAsync({
        workspaceId,
        ...merge({}, shortIdInfo, props),
        objectId: formId,
        shortId: shortIdInfo.id,
        boardContext: shortIdInfo.boardContext,
      });
    },
    [formId, shortIdInfo, updateShortId, currentWorkspace?.id],
  );

  return {
    onShortUrlUpdate,
    isLoading: updateShortId.isLoading,
  };
};
