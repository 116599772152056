import { useTrackUpdateLibraryDetails } from '@air/analytics';
import { Libraries, LibraryListResponse } from '@air/api';
import { UpdateLibraryInput } from '@air/api/dist/libraries';
import { Library } from '@air/api/types';
import { InfiniteData, useMutation } from '@tanstack/react-query';

import { isLibariesKey } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { getLibraryKey } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { queryClient } from '~/swr-hooks/queryClient';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type UseLibraryUpdateMutationParams = {
  libraryId: Library['id'];
};

export const useLibraryUpdateMutation = ({ libraryId }: UseLibraryUpdateMutationParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackUpdateLibraryDetails } = useTrackUpdateLibraryDetails();

  const libraryUpdateMutation = useMutation(
    (variables: UpdateLibraryInput) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.update({ workspaceId, libraryId, update: variables });
    },
    {
      onMutate: (variables) => {
        const previousLibrary = queryClient.getQueryData<Library>(getLibraryKey(libraryId));

        if (previousLibrary) {
          queryClient.setQueryData<Library>(getLibraryKey(libraryId), {
            ...previousLibrary,
            ...variables,
          });
        }

        const allPreviousLibraries = queryClient.getQueriesData<InfiniteData<LibraryListResponse> | undefined>({
          predicate: ({ queryKey }) => {
            return isLibariesKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
          },
        });

        allPreviousLibraries.forEach((previousLibraries) => {
          const previousLibrariesKey = previousLibraries[0];
          const previousLibrariesData = previousLibraries[1];

          if (!!previousLibraries && !!previousLibrariesData) {
            queryClient.cancelQueries(previousLibrariesKey);

            queryClient.setQueriesData(previousLibrariesKey, {
              ...previousLibrariesData,
              pages: previousLibrariesData?.pages.map((page) => ({
                ...page,
                data: page.data.map((library) =>
                  library.id === libraryId
                    ? {
                        ...library,
                        ...variables,
                      }
                    : library,
                ),
              })),
            });
          }
        });

        return {
          allPreviousLibraries,
          previousLibrary,
        };
      },

      onError: (error, variables, context) => {
        if (context) {
          queryClient.setQueryData(getLibraryKey(libraryId), context.previousLibrary);

          context?.allPreviousLibraries.forEach((previousLibraries) => {
            queryClient.setQueriesData(previousLibraries[0], previousLibraries[1]);
          });
        }

        reportErrorToBugsnag({
          error,
          context: 'Unable to update library',
          metadata: {
            key: 'data',
            data: { libraryId, variables },
          },
        });
      },
      onSuccess: (_data, variables) => {
        trackUpdateLibraryDetails({
          library_id: libraryId,
          library_name: variables.title,
          library_accessibility: variables.accessibility,
          library_visibility: variables.visibility,
        });

        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => {
            return isLibariesKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
          },
        });

        queryClient.invalidateQueries(getLibraryKey(libraryId));
      },
    },
  );

  return { libraryUpdateMutation };
};
