import { Members } from '@air/api';
import { MemberWithEmail } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getWorkspaceTotalsKey } from '~/swr-hooks/workspaces/useWorkspaceTotals';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getWorkspaceMembersKey } from './useGetWorkspaceMembers';

export const useAddWorkspaceMembers = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const addWorkspaceMember = async (newMembers: MemberWithEmail[]) => {
    try {
      const workspaceId = currentWorkspace?.id;
      invariant(workspaceId, 'No workspace id');
      const { members } = await Members.add({ workspaceId, members: newMembers });

      await Promise.all([
        queryClient.invalidateQueries(getWorkspaceMembersKey(workspaceId)),
        queryClient.invalidateQueries(getWorkspaceTotalsKey(workspaceId)),
      ]);

      return members;
    } catch (error) {
      throw reportErrorToBugsnag({
        error,
        context: 'Failed to add workspace members',
        metadata: {
          data: {
            members: JSON.stringify(newMembers),
          },
        },
      });
    }
  };

  return {
    addWorkspaceMember,
  };
};
