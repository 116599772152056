import { type Pagination } from '@air/api/types';
import { isUndefined } from 'lodash';

import {
  type RecentlyDeletedBoardItem,
  type RecentlyDeletedLibraryItem,
} from '~/components/RecentlyDeleted/temp/types';

const ITEMS_PER_PAGE = 10;

const LIMIT = 30;

export type RecentlyDeletedFetcherParams = {
  cursor: any;
};

export type RecentlyDeletedResponse = {
  data: (RecentlyDeletedLibraryItem | RecentlyDeletedBoardItem)[];
  pagination: Pagination;
  total: number;
};

export const recentlyDeletedFetcher = async ({
  cursor,
}: RecentlyDeletedFetcherParams): Promise<RecentlyDeletedResponse> => {
  const data: (RecentlyDeletedLibraryItem | RecentlyDeletedBoardItem)[] = new Array(ITEMS_PER_PAGE)
    .fill(0)
    .map((_, index) => ({
      id: `${cursor ? cursor + index : index}`,
      dateDeleted: '2023-05-30T09:14:30Z',
      daysToRecover: 100,
      deletedBy: {
        id: `${cursor ? cursor + index : index}`,
        name: 'Andy',
      },
      title:
        index % 2
          ? `Board title ${cursor ? cursor + index : index}`
          : `Library title ${cursor ? cursor + index : index}`,
      type: index % 2 ? 'board' : 'library',
    }));

  // eslint-disable-next-line no-console
  console.log('fetching...');

  await new Promise((r) => setTimeout(r, 500));

  return {
    data,
    pagination: {
      hasMore: cursor && cursor < LIMIT,
      cursor: isUndefined(cursor)
        ? ITEMS_PER_PAGE + 1
        : cursor > LIMIT - ITEMS_PER_PAGE
        ? null
        : cursor + ITEMS_PER_PAGE,
    },
    total: LIMIT,
  };
};
