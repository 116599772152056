import { Tasks } from '@air/api';
import { TaskType as RemoteTaskType } from '@air/api/types';
import { useCallback } from 'react';

import { TaskType as LocalTaskType } from '~/store/tasks/types';

import { SharedSyncTasksParams, useSharedSyncTasks } from './useSharedSyncTasks';

interface PrivateSyncTasksParams<LocalType extends LocalTaskType, RemoteType extends RemoteTaskType>
  extends Omit<SharedSyncTasksParams<LocalType, RemoteType>, 'getTask'> {}

export const usePrivateSyncTasks = <
  LocalType extends LocalTaskType = LocalTaskType,
  RemoteType extends RemoteTaskType = RemoteTaskType,
>({
  workspaceId,
  ...rest
}: PrivateSyncTasksParams<LocalType, RemoteType>) => {
  const getTask = useCallback(
    (taskId: string) => {
      if (!workspaceId) {
        throw new Error('workspaceId is not defined');
      }

      return Tasks.getTask({ taskId, workspaceId });
    },
    [workspaceId],
  );

  return useSharedSyncTasks({
    ...rest,
    workspaceId,
    getTask,
  });
};
