import { Workspaces } from '@air/api';
import { InviteToken } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getWorkspaceTokensKey } from './useInviteTokens';

export const useCreateInviteToken = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const createInviteToken = useCallback(
    async (roleId: string) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      try {
        const newToken = await Workspaces.createInviteToken({ roleId, workspaceId });

        queryClient.setQueryData<InviteToken[] | undefined>(getWorkspaceTokensKey(workspaceId), (tokens) => {
          return tokens ? [newToken, ...tokens] : undefined;
        });

        queryClient.invalidateQueries(getWorkspaceTokensKey());
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to create invite token',
          metadata: {
            Data: {
              roleId,
            },
          },
        });
      }
    },
    [queryClient, workspaceId],
  );

  return {
    createInviteToken,
  };
};
