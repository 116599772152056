import { ClipStatus, ClipType, type NotificationClip as NotificationClipType } from '@air/api/types';
import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';

import { ClipThumbnail } from '~/components/Shared/ClipList/ClipItem/ClipThumbail';
import { ClipIconSize } from '~/types/clip-icon-size';

export const notificationClip = tailwindVariants({
  base: 'relative flex h-14 w-14 items-center justify-center rounded',
  variants: {
    size: {
      medium: 'h-14 w-14',
      small: 'h-10 w-10',
    },
    status: {
      transcoded: 'bg-transparent',
      default: 'bg-pigeon-50',
    },
  },
});

export type NotificationClipVariant = VariantProps<typeof notificationClip>;

export type NotificationClipProps = Pick<NotificationClipVariant, 'status'> & {
  clip: NotificationClipType;
};

export const NotificationClip = ({ clip }: NotificationClipProps) => {
  return (
    <div
      className={notificationClip({
        status: status === 'transcoded' ? 'transcoded' : 'default',
      })}
    >
      <ClipThumbnail
        src={clip.assets.image || ''}
        iconSize={ClipIconSize.small}
        type={ClipType.photo}
        ext={clip.ext}
        status={status === ClipStatus.transcoded ? clip.status : ClipStatus.nonTranscodable}
        width={56}
        height={56}
        tx={{
          img: {
            objectFit: 'cover',
            height: `inherit !important`,
          },
        }}
      />
    </div>
  );
};
