import { CustomFields } from '@air/api';
import { CustomFieldColorListResponse } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { CUSTOM_FIELD_COLORS_LIST } from '~/constants/react-query-keys';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { canSeeCustomFields } from '~/utils/permissions/workspacePermissions';

export const useGetCustomFieldColors = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  return useQuery<CustomFieldColorListResponse>(
    [CUSTOM_FIELD_COLORS_LIST],
    () => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return CustomFields.listCustomFieldColors({ workspaceId });
    },
    {
      enabled: !!workspaceId && canSeeCustomFields(workspacePermissions),
    },
  );
};
