import { noop } from 'lodash';
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { IIdleTimer, IIdleTimerProps, useIdleTimer } from 'react-idle-timer';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface InactivityContextInterface extends Pick<IIdleTimer, 'activate' | 'getRemainingTime' | 'start' | 'pause'> {
  setProps: (props: IIdleTimerProps) => void;
}

const defaultValue: InactivityContextInterface = {
  activate: noop,
  getRemainingTime: () => 0,
  setProps: noop,
  start: noop,
  pause: () => false,
};

export interface InactivityProviderProps {}

const InactivityContext = createContext<InactivityContextInterface>(defaultValue);

export const InactivityProvider = ({ children }: PropsWithChildren<InactivityProviderProps>) => {
  const [props, setProps] = useState<IIdleTimerProps>();

  const { getRemainingTime, activate, start, pause } = useIdleTimer({
    syncTimers: 500,
    crossTab: true,
    throttle: 500,
    timeout: 120_000,
    promptBeforeIdle: 5_000,
    startOnMount: false,
    startManually: true,
    ...props,
  });

  const value = useMemo(
    (): InactivityContextInterface => ({
      activate,
      getRemainingTime,
      setProps,
      start,
      pause,
    }),
    [activate, getRemainingTime, pause, start],
  );

  return <InactivityContext.Provider value={value}>{children}</InactivityContext.Provider>;
};

export function useInactivity() {
  const inactivity = useContext(InactivityContext);

  if (inactivity === defaultValue) {
    const error = 'InactivityProviderContext used outside of InactivityProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return inactivity;
}
