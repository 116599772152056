import { SavedFilters } from '@air/api';
import { SavedFilter } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const SAVED_FILTER = 'SAVED_FILTER';

export const getSavedFilterKey = (savedFilterId?: string) => {
  return [SAVED_FILTER, { savedFilterId }];
};

export const useGetSavedFilter = (savedFilterId?: SavedFilter['id']) => {
  const { currentWorkspace } = useCurrentWorkspace();

  return useQuery<SavedFilter, any>(
    getSavedFilterKey(savedFilterId),
    () => {
      return SavedFilters.getSavedFilter(savedFilterId!);
    },
    { enabled: !!currentWorkspace?.id && !!savedFilterId },
  );
};
