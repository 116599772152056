import { Library } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { Spinner } from '@air/primitive-spinner';

import { useLibraryLeaveMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryLeaveMutation';
import { useCanLeaveLibrary } from '~/components/LibraryBeta/hooks/useCanLeaveLibrary';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useAccount } from '~/swr-hooks/account/useAccount';
import { convertUnknownToError } from '~/utils/ErrorUtils';

export type LibraryLeaveModalProps = AirModalProps<Required<Pick<Library, 'id' | 'title'>>>;

export const LibraryLeaveModal = ({ id, onClose, title }: LibraryLeaveModalProps) => {
  const { showLibraryToast } = useLibraryToasts();
  const { data: account, isInitialLoading: isAccountLoading } = useAccount();
  const { libraryLeaveMutation } = useLibraryLeaveMutation({ libraryId: id });
  const { canLeaveLibrary, isLoading: isCanLeaveLibraryLoading } = useCanLeaveLibrary({ libraryId: id });

  return (
    <Modal className="flex flex-col gap-4" isOpen onDismiss={onClose}>
      {isCanLeaveLibraryLoading ? (
        <Spinner className="h-6 w-6 self-center justify-self-center text-blue-9" />
      ) : (
        <>
          <header className="flex justify-between gap-2">
            <ModalTitle>{canLeaveLibrary ? `Leave ${title}?` : 'Can’t leave this library'}</ModalTitle>
            <ModalCloseButton onClick={onClose} />
          </header>

          <ModalDescription>
            {canLeaveLibrary
              ? `Are you sure you want to leave ${title}? You'll no longer see this library in your sidebar and may lose permission view its contents.`
              : 'You cannot leave this library as you are the only Library Owner. Add an additional owner to proceed.'}
          </ModalDescription>

          <footer className="flex items-center justify-end gap-2">
            {canLeaveLibrary ? (
              <>
                <Button appearance="ghost" color="grey" onClick={onClose} size="large">
                  Cancel
                </Button>
                <Button
                  appearance="filled"
                  color="blue"
                  disabled={isAccountLoading}
                  isLoading={libraryLeaveMutation.isLoading}
                  onClick={() => {
                    if (account?.id) {
                      libraryLeaveMutation.mutate(
                        { accountId: account?.id },
                        {
                          onError: (error) =>
                            showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
                          onSuccess: () => {
                            showLibraryToast({
                              message: (
                                <>
                                  Left <b className="text-jay-200">{title}</b> library.
                                </>
                              ),
                            });
                            onClose();
                          },
                        },
                      );
                    }
                  }}
                  size="large"
                >
                  Leave
                </Button>
              </>
            ) : (
              <Button appearance="filled" color="blue" onClick={onClose} size="large">
                Okay
              </Button>
            )}
          </footer>
        </>
      )}
    </Modal>
  );
};
