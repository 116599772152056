import { useTrackShownInactivityModal } from '@air/analytics';
import { Button } from '@air/primitive-button';
import { Modal } from '@air/zephyr';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useInactivity } from '~/providers/InactivityProvider';
import { useLogout } from '~/swr-hooks/auth/useLogout';

import { PROMPT_BEFORE_IDLE_SECONDS } from '../Layouts/HomeLayout/hooks/useHandleInactivity';

export interface InactivityAlertModalProps extends AirModalProps {}

export const InactivityAlertModal = ({ onClose }: InactivityAlertModalProps) => {
  const dismissButtonRef = useRef<HTMLButtonElement>(null);
  const { getRemainingTime, activate } = useInactivity();
  const calculateTotalRemainingSeconds = useCallback(() => Math.ceil(getRemainingTime() / 1000), [getRemainingTime]);
  const [totalRemainingSeconds, setTotalRemainingSeconds] = useState(calculateTotalRemainingSeconds());
  const { logout } = useLogout();
  const { trackShownInactivityModal } = useTrackShownInactivityModal();

  useEffect(() => {
    trackShownInactivityModal();
  }, [trackShownInactivityModal]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalRemainingSeconds(calculateTotalRemainingSeconds());
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [calculateTotalRemainingSeconds]);

  /**
   * There's a bug we've noticed where sometimes this modal is fired way before it should be.
   * If that happens, this code should detect that and then hide it.
   */
  useEffect(() => {
    if (totalRemainingSeconds > PROMPT_BEFORE_IDLE_SECONDS) {
      onClose();
    }
  }, [onClose, totalRemainingSeconds]);

  const minutes = Math.floor(totalRemainingSeconds / 60);
  const seconds = totalRemainingSeconds % 60;

  return (
    <Modal
      onDismiss={onClose}
      isAlertModal
      modalLabel="Your session is about to expire"
      leastDestructiveRef={dismissButtonRef}
      modalDescription={
        <div>
          <p className="mb-4 text-16">For your security, your session will expire shortly</p>
          <p>
            You will be logged out in{' '}
            <span className="inline font-bold">
              {minutes ? pluralize('minute', minutes, true) : ''}
              {seconds && minutes ? ' ' : ''}
              {seconds ? pluralize('second', seconds, true) : ''}
            </span>
            .
          </p>
        </div>
      }
      withCloseButton={false}
    >
      <div className="mt-8 flex justify-end">
        <Button
          onClick={() => {
            logout();
            onClose();
          }}
          appearance="ghost"
          color="grey"
          className="mr-4"
          size="large"
        >
          Logout
        </Button>
        <Button
          ref={dismissButtonRef}
          onClick={() => {
            activate();
            onClose();
          }}
          appearance="filled"
          color="blue"
          size="large"
        >
          Stay logged in
        </Button>
      </div>
    </Modal>
  );
};
