import { Workspaces } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { ONE_DAY } from '~/constants/unitsOfTime';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { canSeePrivateWorkspaceDetails } from '~/utils/permissions/workspacePermissions';

export const WORKSPACES_TOTALS = 'WORKSPACES_TOTALS';

export const getWorkspaceTotalsKey = (workspaceId?: string) => {
  return workspaceId ? [WORKSPACES_TOTALS, { workspaceId }] : [WORKSPACES_TOTALS];
};

/** This hook returns the totals (total assets, bytes used, and members) for the workspace */
export function useWorkspaceTotals() {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const { data: permissions } = useCurrentWorkspacePermissionsContext();

  return useQuery(
    getWorkspaceTotalsKey(workspaceId),
    () => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return Workspaces.totals({ workspaceId });
    },
    {
      staleTime: ONE_DAY,
      enabled: !!workspaceId && canSeePrivateWorkspaceDetails(permissions),
    },
  );
}
