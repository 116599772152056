import { Subnav } from '@air/api';
import { SortDirection } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { SUBNAV_SORT_OPTIONS } from '~/constants/react-query-keys';
import { ONE_DAY } from '~/constants/unitsOfTime';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const useSubnavSortOptions = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  return useQuery(
    [SUBNAV_SORT_OPTIONS],
    async () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      const { data: sortOptions } = await Subnav.listSubnavBoardSortOptions({ workspaceId });
      const sortOptionsData: { displayName: string; name: string; direction: SortDirection }[] = [];

      sortOptions.forEach((option) => {
        if (option === 'customSort') {
          sortOptionsData.push({ displayName: 'Custom Sort', name: option, direction: SortDirection.desc });
        } else if (option === 'name') {
          sortOptionsData.push({ displayName: 'A to Z', name: option, direction: SortDirection.asc });
          sortOptionsData.push({ displayName: 'Z to A', name: option, direction: SortDirection.desc });
        } else if (option === 'dateCreated') {
          sortOptionsData.push({ displayName: 'Newest', name: option, direction: SortDirection.desc });
          sortOptionsData.push({ displayName: 'Oldest', name: option, direction: SortDirection.asc });
        }
      });

      return sortOptionsData;
    },
    {
      enabled: !!currentWorkspace,
      staleTime: ONE_DAY,
    },
  );
};
