import { SavedFilters } from '@air/api';
import { SavedFilter, SavedFiltersListResponse, UpdateSavedFilterInput } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { createEmptyListResponseStructure } from '~/utils/ApiUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getSavedFilterKey } from './useGetSavedFilter';
import { getSavedFiltersKey } from './useGetSavedFilters';

export const useUpdateSavedFilter = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const updateSavedFilter = useMutation({
    mutationFn: (updatedSavedFilter: UpdateSavedFilterInput) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return SavedFilters.updateSavedFilter({ workspaceId, update: updatedSavedFilter });
    },
    // When mutate is called:
    onMutate: async (updatedSavedFilter) => {
      if (!currentWorkspace?.id) {
        return;
      }

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(getSavedFiltersKey(currentWorkspace.id));

      // Snapshot the previous value
      const previousSavedFilters = queryClient.getQueryData<SavedFiltersListResponse>(
        getSavedFiltersKey(currentWorkspace.id),
      );

      // if it exists (which it should), update the individual saved filter in cache
      queryClient.setQueryData<SavedFilter | undefined>(getSavedFilterKey(updatedSavedFilter.id), (cache) =>
        cache
          ? {
              ...cache,
              ...updatedSavedFilter,
            }
          : undefined,
      );

      // if it exists (which it should), update the individual saved filter in the list
      queryClient.setQueryData<SavedFiltersListResponse>(getSavedFiltersKey(currentWorkspace.id), (cache) =>
        cache
          ? {
              ...cache,
              data: cache.data.map((sv) =>
                sv.id === updatedSavedFilter.id
                  ? {
                      ...sv,
                      ...updatedSavedFilter,
                    }
                  : sv,
              ),
            }
          : createEmptyListResponseStructure(),
      );

      // Return a context object with the snapshotted value
      return { previousSavedFilters };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (error, updatedSavedFilter, context) => {
      if (!currentWorkspace?.id) {
        return;
      }

      reportErrorToBugsnag({
        error,
        context: 'Unable to update saved filter',
        metadata: {
          key: 'data',
          data: { updatedSavedFilter, workspaceId: currentWorkspace?.id },
        },
      });

      queryClient.setQueryData(getSavedFiltersKey(currentWorkspace.id), context?.previousSavedFilters);
    },
  });

  return {
    updateSavedFilter,
  };
};
