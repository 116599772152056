import { Account, Auth } from '@air/api';
import { matchesAirror, WORKSPACE_INTEGRATION_SSO_NOT_ENABLED } from '@air/errors';
import { getCurrentUserInfoKey } from '@air/utils-auth';
import { useQueryClient } from '@tanstack/react-query';
import Router from 'next/router';
import { useCallback } from 'react';

import { Routes } from '~/constants/routes';
import { getCurrentSessionKey } from '~/swr-hooks/account/useGetCurrentUserSession';
import { getListApprovedWorkspacesKey } from '~/swr-hooks/workspaces/useListApprovedWorkspaces';

export const useHandleNoWorkspaces = () => {
  const queryClient = useQueryClient();

  const handleNoWorkspaces = useCallback(
    async (redirect?: string) => {
      Router.ready(async () => {
        const [userInfo, approvedWorkspaces] = await Promise.all([
          queryClient.fetchQuery({
            queryKey: getCurrentUserInfoKey(),
            queryFn: Auth.getCurrentUserInfo,
          }),
          queryClient.fetchQuery({
            queryKey: getListApprovedWorkspacesKey(),
            queryFn: () =>
              Account.listApprovedWorkspaces().catch((error) => {
                if (matchesAirror(error, WORKSPACE_INTEGRATION_SSO_NOT_ENABLED)) {
                  return { data: [] };
                } else {
                  throw error;
                }
              }),
          }),
        ]);

        queryClient.invalidateQueries({ queryKey: getCurrentSessionKey() });

        /**
         * If they have an unverified email or have never created a workspace,
         * send them to create a workspace. We don't allow unverified users to see
         * the join available workspace screen because malicious users could join workspaces
         * with emails that aren't really theirs.
         */
        if (!userInfo.attributes.email_verified || !approvedWorkspaces.data.length) {
          Router.push(Routes.onboarding.initial);
        } else {
          Router.push({
            pathname: Routes.joinApprovedWorkspace,
            query: {
              redirect,
            },
          });
        }
      });
    },
    [queryClient],
  );

  return {
    handleNoWorkspaces,
  };
};
