import { SavedFilters } from '@air/api';
import { CreateSavedFilterInput, SavedFilter, SavedFiltersListResponse } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { createEmptyListResponseStructure } from '~/utils/ApiUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getSavedFilterKey } from './useGetSavedFilter';
import { getSavedFiltersKey } from './useGetSavedFilters';

export const useCreateSavedFilter = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const createSavedFilter = useMutation({
    mutationFn: (savedFilter: CreateSavedFilterInput) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return SavedFilters.createSavedFilter({ workspaceId, savedFilter });
    },
    // When mutate is called:
    onMutate: async () => {
      if (!currentWorkspace?.id) {
        return;
      }

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(getSavedFiltersKey(currentWorkspace.id));

      // Snapshot the previous value
      const previousSavedFilters = queryClient.getQueryData<SavedFiltersListResponse>(
        getSavedFiltersKey(currentWorkspace.id),
      );

      // Return a context object with the snapshotted value
      return { previousSavedFilters };
    },
    onSuccess: (createdSavedFilter) => {
      if (!currentWorkspace?.id) {
        return;
      }

      /** Inject the new saved filter into local cache */
      queryClient.setQueryData<SavedFilter>(getSavedFilterKey(createdSavedFilter.id), createdSavedFilter);

      /** Inject the new saved filter into local cache for the saved filters list */
      queryClient.setQueryData<SavedFiltersListResponse>(getSavedFiltersKey(currentWorkspace.id), (cache) =>
        cache
          ? {
              ...cache,
              data: [...cache.data, createdSavedFilter],
            }
          : {
              ...createEmptyListResponseStructure(),
              data: [createdSavedFilter],
              total: 1,
            },
      );
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (error, savedFilter, context) => {
      if (!currentWorkspace?.id) {
        return;
      }

      reportErrorToBugsnag({
        error,
        context: 'Unable to create saved filter',
        metadata: {
          key: 'Data',
          data: { savedFilter, workspaceId: currentWorkspace?.id },
        },
      });

      queryClient.setQueryData(getSavedFiltersKey(currentWorkspace.id), context?.previousSavedFilters);
    },
  });

  return {
    createSavedFilter,
  };
};
