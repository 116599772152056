import { Text } from '@air/zephyr';

import { NOTIFICATIONS_NONE } from '~/constants/testIDs';

import EmptyNotifications from '../../UI/EmptyNotifications';

export const NotificationEmpty = () => {
  return (
    <div
      className="flex h-full min-h-[400px] w-full flex-col items-center justify-center"
      data-testid={NOTIFICATIONS_NONE}
    >
      <EmptyNotifications size={[136, 136, 96]} />
      <Text
        as="label"
        variant={['text-ui-24', 'text-ui-24', 'text-ui-16']}
        tx={{ fontWeight: 'medium', color: 'pigeon700', mt: 16 }}
      >
        You have no notifications
      </Text>
    </div>
  );
};
