import { type FetchNextPageOptions, type InfiniteQueryObserverResult } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import { type ComponentProps, type ReactElement, type ReactNode, useEffect, useRef } from 'react';

export type NotificationsListProps<Notification, NotificationResponse> = ComponentProps<'div'> & {
  getEstimateSize?: (args: { index: number; notification: Notification }) => number;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  notifications: Notification[];
  onFetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<NotificationResponse, unknown>>;
  renderLoader: (args: { index: number; notification: Notification }) => ReactNode;
  renderNotification: (args: { index: number; notification: Notification }) => ReactNode;
  renderNullState: (args: { notifications: Notification[] }) => ReactElement;
};

export const NotificationsList = <Notification, NotificationResponse>({
  getEstimateSize,
  hasNextPage,
  isFetchingNextPage,
  notifications,
  onFetchNextPage,
  renderLoader,
  renderNotification,
  renderNullState,
  ...restOfProps
}: NotificationsListProps<Notification, NotificationResponse>) => {
  const listRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: hasNextPage ? notifications.length + 1 : notifications.length,
    getScrollElement: () => listRef.current,
    estimateSize: (index) => getEstimateSize?.({ index, notification: notifications[index] }) ?? 148,
  });

  const items = virtualizer.getVirtualItems();

  /**
   * If the last item is visible, fetch the next page.
   */
  useEffect(() => {
    const [lastItem] = [...items].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= notifications.length - 1 && hasNextPage && !isFetchingNextPage) {
      onFetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, items, notifications.length, onFetchNextPage]);

  if (!notifications.length) return renderNullState({ notifications });

  return (
    <div className="h-full overflow-y-auto" data-testid="NOTIFICATIONS_LIST" ref={listRef} {...restOfProps}>
      <div
        className="relative w-full"
        style={{
          height: `${virtualizer.getTotalSize()}px`,
        }}
      >
        <ul
          className="absolute left-0 top-0 w-full"
          style={{
            transform: `translateY(${items?.[0]?.start - virtualizer.options.scrollMargin}px)`,
          }}
        >
          {virtualizer.getVirtualItems().map((virtualRow) => {
            const notification = notifications[virtualRow.index];
            const index = virtualRow.index;
            const isLoaderRow = virtualRow.index > notifications.length - 1;

            return (
              <li data-index={index} key={virtualRow.key} ref={virtualizer.measureElement}>
                {isLoaderRow ? renderLoader({ index, notification }) : renderNotification({ index, notification })}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
