import { useSelector } from 'react-redux';

import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { centralizedBoardIdSelector } from '~/store/centralizedBoard/selectors';
import { canCreateAsset } from '~/utils/permissions/assetPermissions';

export const useCanUploadAssets = () => {
  const currentBoardId = useSelector(centralizedBoardIdSelector);
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { boardPermissions } = useCurrentBoardPermissions();
  const permissions = currentBoardId ? boardPermissions : workspacePermissions;
  const canUploadAssets = canCreateAsset(permissions);

  return {
    canUploadAssets,
  };
};
