import { type ListResponse } from '@air/api/types';

export type UseRecentlyDeletedTableViewItemsParams = {
  data: ListResponse<any>[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isInitialLoading: boolean;
};

export const useRecentlyDeletedTableViewItems = (params: UseRecentlyDeletedTableViewItemsParams) => {
  const fetchNextPage = () => {
    if (params.data && params.hasNextPage && !params.isInitialLoading) {
      params.fetchNextPage();
    }
  };

  const itemData = params.data
    ? params.data.reduce((acc, curr) => {
        const pageData = curr.data.map((data) => ({
          type: data.type,
          item: data,
        }));

        // @ts-ignore - Wait for BE types before fixing this type error.
        acc.push(...pageData);

        return acc;
      }, [])
    : [];

  return {
    data: itemData,
    fetchNextPage,
    hasNextPage: params.hasNextPage,
    isFetchingNextPage: params.isFetchingNextPage,
    isInitialLoading: params.isInitialLoading,
    isEmpty: !!params.data && !params.isInitialLoading && !params.data.some((items) => items.data.length > 0),
    total: params.data ? params.data[params.data.length - 1]?.total : 0,
  };
};
