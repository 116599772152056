import { Account } from '@air/api';
import { isAdminDotAirDotInc } from '@air/sysadmin';
import { useIsLoggedIn } from '@air/utils-auth';
import { useQuery } from '@tanstack/react-query';

export const APPROVED_WORKSPACES = 'APPROVED_WORKSPACES';

export const getListApprovedWorkspacesKey = () => [APPROVED_WORKSPACES];

export const useListApprovedWorkspaces = () => {
  const { isLoggedIn } = useIsLoggedIn();

  return useQuery(getListApprovedWorkspacesKey(), Account.listApprovedWorkspaces, {
    enabled: isLoggedIn && !isAdminDotAirDotInc(),
  });
};
