import { Clips } from '@air/api';
import { Upload, uploadByIdSelector } from '@air/redux-uploader';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useGetPrivateUploadBoardId } from '~/components/Upload/privateUpload/useGetPrivateUploadBoardId';
import {
  ClipGetOptionsWithBoards,
  defaultClipGetOptions,
  getClipWithoutWorkspaceIdKey,
} from '~/swr-hooks/useGetClipWithNoWorkspaceId';
import { useAirStore } from '~/utils/ReduxUtils';

export const useFetchClipForPrivateUpload = () => {
  const store = useAirStore();
  const client = useQueryClient();
  const { getUploadBoardId } = useGetPrivateUploadBoardId();

  const fetchClipForPrivateUpload = useCallback(
    async (uploadId: Upload['id']) => {
      const upload = uploadByIdSelector(store.getState(), uploadId);
      if (!upload) {
        return;
      }

      const boardId = getUploadBoardId(upload);

      const { clipId } = upload;

      const params: ClipGetOptionsWithBoards = {
        ...defaultClipGetOptions,
        boardId,
      };

      const cacheKey = getClipWithoutWorkspaceIdKey({ clipId, options: params });
      const clip = await client.ensureQueryData(cacheKey, () =>
        Clips.getWithNoWorkspaceId({ id: clipId, options: params }),
      );

      return clip;
    },
    [client, getUploadBoardId, store],
  );

  return { fetchClipForPrivateUpload };
};
