import { Notifications } from '@air/api';
import { type ListNotificationsResponse, type NotificationFilter } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const WORKSPACE_NOTIFICATIONS_KEY = 'WORKSPACE_NOTIFICATIONS';

export const getNotificationsKey = (workspaceId?: string, filter?: NotificationFilter) => {
  return workspaceId ? [WORKSPACE_NOTIFICATIONS_KEY, { workspaceId, filter }] : [WORKSPACE_NOTIFICATIONS_KEY];
};

export type UseNotificationsParams = {
  filter?: NotificationFilter;
};

export const useNotifications = (params?: UseNotificationsParams) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const notifications = useInfiniteQuery<ListNotificationsResponse>(
    getNotificationsKey(currentWorkspace?.id, params?.filter),
    ({ pageParam }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Notifications.listNotifications({ workspaceId, cursor: pageParam, filter: params?.filter });
    },
    { enabled: !!currentWorkspace?.id, getNextPageParam: (lastPage) => lastPage.cursor },
  );

  return {
    ...notifications,
    data: notifications.data ? notifications.data.pages.flatMap((page) => page.items) : [],
  };
};
