import { useTrackLeaveLibrary } from '@air/analytics';
import { Libraries, LibraryListResponse } from '@air/api';
import type { Library, RemoveLibraryMemberInput } from '@air/api/types';
import { InfiniteData, useMutation } from '@tanstack/react-query';

import { isLibariesKey } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { queryClient } from '~/swr-hooks/queryClient';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type UseLibraryLeaveMutationParams = {
  libraryId: Library['id'];
};

export const useLibraryLeaveMutation = ({ libraryId }: UseLibraryLeaveMutationParams) => {
  const { trackLeaveLibrary } = useTrackLeaveLibrary();
  const { currentWorkspace } = useCurrentWorkspace();

  const libraryLeaveMutation = useMutation(
    (variables: RemoveLibraryMemberInput) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.removeMembers({ workspaceId, libraryId, members: [variables] });
    },
    {
      onMutate: () => {
        const allPreviousLibraries = queryClient.getQueriesData<InfiniteData<LibraryListResponse> | undefined>({
          predicate: ({ queryKey }) => {
            return isLibariesKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
          },
        });

        allPreviousLibraries.forEach((previousLibraries) => {
          const previousLibrariesKey = previousLibraries[0];
          const previousLibrariesData = previousLibraries[1];

          if (!!previousLibraries && !!previousLibrariesData) {
            queryClient.cancelQueries(previousLibrariesKey);

            queryClient.setQueriesData(previousLibrariesKey, {
              ...previousLibrariesData,
              pages: previousLibrariesData?.pages.map((page) => ({
                ...page,
                data: page.data.filter((library) => library.id !== libraryId),
              })),
            });
          }
        });

        return { allPreviousLibraries };
      },
      onError: (error, variables, context) => {
        context?.allPreviousLibraries.forEach((previousLibraries) => {
          queryClient.setQueriesData(previousLibraries[0], previousLibraries[1]);
        });

        reportErrorToBugsnag({
          error,
          context: 'Failed to leave from library',
          metadata: {
            data: {
              libraryId,
              user: variables,
            },
          },
        });
      },

      onSuccess: (_data, variables) => {
        trackLeaveLibrary({
          library_id: libraryId,
          library_member_id: variables.accountId,
        });
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => {
            return isLibariesKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
          },
        });
      },
    },
  );

  return { libraryLeaveMutation };
};
