import { Library, WorkspaceMemberRole, WorkspaceMemberType, WorkspaceUserRole } from '@air/api/types';
import { SelectDropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { isString } from 'lodash';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';

import { useLibraryMembersAddMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryMembersAddMutation';
import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { useLibraryMembers } from '~/components/LibraryBeta/hooks/useLibraryMembers';
import { useLibraryPermissions } from '~/components/LibraryBeta/hooks/useLibraryPermissions';
import { LibraryInviteMemberChip } from '~/components/LibraryBeta/LibraryInviteMember/components/LibraryInviteMemberChip';
import { LibraryInviteMemberListItem } from '~/components/LibraryBeta/LibraryInviteMember/components/LibraryInviteMemberListItem';
import { LibraryInviteToWorkspaceModal } from '~/components/LibraryBeta/LibraryInviteMember/components/LibraryInviteToWorkspaceModal';
import {
  LibraryInviteMember,
  LibraryInviteMemberChipType,
  LibraryInviteMemberProps,
} from '~/components/LibraryBeta/LibraryInviteMember/LibraryInviteMember';
import { SelectProps } from '~/components/Zephyr/Select/Select';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';
import { useWorkspaceRoles } from '~/swr-hooks/workspaces/useWorkspaceRoles';
import { isValidEmailAddress } from '~/utils/EmailUtils';
import { convertUnknownToError } from '~/utils/ErrorUtils';
import { isPermissionsGTE } from '~/utils/PermissionsUtils';

export type LibraryInviteMemberContainerProps = {
  libraryId: Library['id'];
};

export const LibraryInviteMemberContainer = ({ libraryId }: LibraryInviteMemberContainerProps) => {
  const { data: roles } = useWorkspaceRoles();
  const { members } = useWorkspaceMembers();
  const { data: library } = useLibrary({ libraryId });
  const { data: libraryMembers } = useLibraryMembers({
    libraryId,
    options: {
      hideSysadmins: true,
      withPendingWorkspaceMembers: true,
    },
  });
  const { libraryMembersAddMutation } = useLibraryMembersAddMutation({ libraryId });
  const { showLibraryToast } = useLibraryToasts();
  const defaultRole = roles?.find((role) => role.id === library?.defaultRoleId);
  const [selectedMembers, setSelectedMembers] = useState<LibraryInviteMemberChipType[]>([]);
  const [selectedRole, setSelectRole] = useState<WorkspaceUserRole | undefined>(defaultRole);
  const [showWorkspaceInviteConfirm] = useAirModal(LibraryInviteToWorkspaceModal);
  const { libraryPermissions } = useLibraryPermissions({ libraryId });

  const libraryRoles: SelectDropdownMenuOption[] | undefined = useMemo(() => {
    return roles
      ?.filter(({ type }) => type === WorkspaceMemberType.libraryMember)
      .map((role) => ({
        description: isString(role.description) ? role.description : undefined,
        disabled: !libraryPermissions || !isPermissionsGTE(libraryPermissions, role.permissions),
        id: role.id,
        label: role.displayName.replace('Library ', ''),
        onSelect: () => setSelectRole(role),
        type: 'item',
      }));
  }, [libraryPermissions, roles]);

  const nonWorkspaceMembers = useMemo(() => {
    return selectedMembers.filter((selectedMember) => {
      return !members?.activeMembers.find((member) => member.accountId === selectedMember.accountId);
    });
  }, [members, selectedMembers]);

  const helperText = useMemo(() => {
    if (!nonWorkspaceMembers.length) return undefined;

    return `${pluralize('user', nonWorkspaceMembers.length, true)} will also be invited to the
        workspace as an Editor`;
  }, [nonWorkspaceMembers]);

  const removeMember = useCallback((email: string) => {
    setSelectedMembers((prev) => prev.filter((member) => member.email !== email));
  }, []);

  const chipRenderer = useCallback(
    (member: LibraryInviteMemberChipType) => {
      return (
        <LibraryInviteMemberChip
          avatar={member.avatar ?? undefined}
          colorSeed={member.accountId ?? undefined}
          label={member.label || member.email}
          onRemoveClick={() => removeMember(member.email)}
        />
      );
    },
    [removeMember],
  );

  const listItemRenderer = useCallback((value: LibraryInviteMemberChipType) => {
    return (
      <LibraryInviteMemberListItem
        avatar={value.avatar ?? undefined}
        className="py-2"
        colorSeed={value.accountId ?? undefined}
        label={value.pending ? value.email : value.label}
        meta={value.pending ? 'Pending...' : value.email}
        suffix={value.memberRole}
      />
    );
  }, []);

  const options = useMemo(() => {
    if (!members?.activeMembers || !libraryMembers?.length) return [];

    return members.activeMembers
      .filter((member) => !libraryMembers.find((libraryMember) => member.accountId === libraryMember.accountId))
      .map((member) => ({
        ...member,
        label: `${member.firstName} ${member.lastName}`,
        value: member.email,
      }));
  }, [members?.activeMembers, libraryMembers]);

  const onSelectionChange = useCallback<LibraryInviteMemberProps['onSelectionChange']>((values) => {
    setSelectedMembers([...values.chips]);
  }, []);
  ``;

  const onNewMemberSelect = useCallback<Required<LibraryInviteMemberProps>['onCreate']>((value) => {
    setSelectedMembers((prev) => {
      return [
        ...prev,
        {
          email: value,
          value,
          label: value,
          avatar: '',
          memberRole: WorkspaceMemberRole.member,
          accountId: '',
          pending: true,
          lastName: '',
          firstName: '',
        },
      ];
    });
  }, []);

  const inviteMembers = useCallback(async () => {
    libraryMembersAddMutation.mutate(
      selectedMembers.map((member) =>
        member.accountId
          ? {
              accountId: member.accountId,
              roleId: selectedRole?.id,
            }
          : {
              email: member.value,
              roleId: selectedRole?.id,
            },
      ),
      {
        onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
        onSuccess: () => {
          showLibraryToast({
            message: (
              <>
                Invited <b className="text-jay-200">{pluralize('user', selectedMembers.length, true)}</b> to library.
              </>
            ),
          });

          setSelectedMembers([]);
        },
      },
    );
  }, [libraryMembersAddMutation, selectedMembers, selectedRole?.id, showLibraryToast]);

  const allowValiedUniqEmail = useCallback<Required<SelectProps<LibraryInviteMemberChipType>>['createOptionFilter']>(
    (inputValue) => {
      /*
        We want to allow creating emails only if they are valid emails, are not yet invited, selected,
        and there is no user with that email
       */
      const isAlreadySelected = selectedMembers.find((member) => member.email === inputValue);
      const isAlreadyInvited = libraryMembers?.find((member) => member.email === inputValue);
      const isInOptions = options.find((option) => option.value === inputValue);
      return !isAlreadySelected && !isAlreadyInvited && !isInOptions && isValidEmailAddress(inputValue);
    },
    [libraryMembers, options, selectedMembers],
  );

  return (
    <LibraryInviteMember
      chipRenderer={chipRenderer}
      listItemRenderer={listItemRenderer}
      onSelectionChange={onSelectionChange}
      options={options}
      buttonText={selectedMembers.length > 0 ? `Invite ${pluralize('user', selectedMembers.length, true)}` : 'Invite'}
      createOptionFilter={allowValiedUniqEmail}
      onInviteMember={() => {
        if (nonWorkspaceMembers.length === 0) {
          inviteMembers();
        } else {
          showWorkspaceInviteConfirm({
            nonWorkspaceMembers: nonWorkspaceMembers.map((member) => member.email),
            onConfirm: () => inviteMembers(),
          });
        }
      }}
      helperText={helperText}
      isInviting={libraryMembersAddMutation.isLoading}
      roles={libraryRoles}
      selectedRole={selectedRole}
      selectedOptions={selectedMembers}
      onCreate={onNewMemberSelect}
    />
  );
};
